import React from 'react'
import './not-found-explore.scss'

interface NotFoundExploreProps {
  title: string
  subtitle?: string
}

const NotFoundExplore: React.FC<NotFoundExploreProps> = (props) => {
  return (
    <div className="not-found-explore">
      <img src="assets/not-found-explore.svg" alt="Explore Page - Not Found" />
      <p>{props.title}</p>
      <span>{props.subtitle}</span>
    </div>
  )
}

export default NotFoundExplore
