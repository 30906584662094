import ExplorePage from 'presentation/pages/explore/explore'
import React from 'react'
import { makeExploreService } from '../services/explore-service-factory'
import { makePatientsService } from '../services/patients-service-factory'
import { makeUserService } from '../services/user-service-factory'

const userService = makeUserService()
const patientsService = makePatientsService()
const exploreService = makeExploreService()

const makeExplorePage: React.FC = () => {
  return <ExplorePage userService={userService} patientsService={patientsService} exploreService={exploreService} />
}

export default makeExplorePage
