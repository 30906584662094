import React, { useEffect, useRef, useState } from 'react'
import { IonButton, IonCheckbox, IonContent, IonFooter, IonInput, IonModal, IonRow, IonSelect, IonSelectOption, IonSpinner, IonToolbar, useIonToast } from '@ionic/react'
import { ModalTitle } from 'presentation/components'
import { add } from 'ionicons/icons'
import { AddGenomicVariantParams } from 'domain/usecases/add-genomic-variant'
import { ReportsService } from 'infra/services/reports-service'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { GenomicVariants } from 'domain/usecases/get-report'

type ModalCreateGenomicVariantProps = React.ComponentPropsWithoutRef<typeof IonModal> & {
  reportId: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  reportsService: ReportsService
  onDidDismiss: () => void
  genomicVariant: GenomicVariants | null
}

const resetValues: z.infer<typeof formSchema> = {
  name: '',
  type: '',
  alteration: '',
  actionability: '',
  mutation: '',
  transcript_id: '',
  coding_sequence_effect: '',
  vaf_type: 'vaf',
  variant_allele_frequency: '',
  variant_allele_frequency_value_from_previous_report: '',
  amplification_status: '',
  suspected_germline: false
}

const baseSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  type: z.string().min(1, { message: 'Type is required' }),
  alteration: z.string().min(1, { message: 'Alteration is required' }),
  actionability: z.string().min(1, { message: 'Actionability is required' }),
  mutation: z.string().min(1, { message: 'Mutation is required' }),
  transcript_id: z.string().nullable(),
  vaf_type: z.enum(['vaf', 'amplification']),
  coding_sequence_effect: z.string().nullable(),
  variant_allele_frequency: z.string().nullable(),
  variant_allele_frequency_value_from_previous_report: z.string().nullable(),
  amplification_status: z.string().nullable(),
  suspected_germline: z.boolean(),
})

const vafSchema = baseSchema.extend({
  vaf_type: z.literal('vaf'),
  variant_allele_frequency: z.string().min(1, { message: 'Variant Allele Frequency is required' }),
  variant_allele_frequency_value_from_previous_report: z.string().min(1, { message: 'Variant Allele Frequency Value from Previous Report is required' }),
  amplification_status: z.string().nullable(),
})

const amplificationSchema = baseSchema.extend({
  vaf_type: z.literal('amplification'),
  amplification_status: z.string().min(1, { message: 'Amplification Status is required' }),
  variant_allele_frequency: z.string().nullable(),
  variant_allele_frequency_value_from_previous_report: z.string().nullable(),
})

const formSchema = z.discriminatedUnion('vaf_type', [vafSchema, amplificationSchema])


const ModalCreateGenomicVariant: React.FC<ModalCreateGenomicVariantProps> = (props) => {
  const vafType: 'vaf' | 'amplification' = props.genomicVariant?.amplification_status ? 'amplification' : 'vaf'
  const [type, setType] = useState<'vaf' | 'amplification'>(vafType)
  const [isSaving, setSaving] = useState(false)
  const [presentToast] = useIonToast()
  const modal = useRef<HTMLIonModalElement>(null)

  const { register, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({
    resolver: zodResolver(formSchema)
  })

  const save = (data: z.infer<typeof formSchema>) => {
    const { vaf_type, ...rest } = data
    const variantData = {
      report_id: props.reportId,
      genomic_variant: {
        ...rest,
        id: props.genomicVariant?.id?.toString(),
        variant_allele_frequency: data.variant_allele_frequency || '',
        variant_allele_frequency_value_from_previous_report: data.variant_allele_frequency_value_from_previous_report || ''
      }
    }

    if(!props.genomicVariant) {
      createGenomicVariant(variantData)
    } else {
      updateGenomicVariant(variantData)
    }
  }

  const createGenomicVariant = (data: AddGenomicVariantParams) => {
    setSaving(true)
    props.reportsService.addGenomicVariant(data)
      .then(() => {
        modal.current?.dismiss({ wasEdited: true })
      })
      .catch((message) => {
        presentToast({
          message,
          duration: 5000,
          color: 'danger',
        })
      })
      .finally(() => setSaving(false))
  }

  const updateGenomicVariant = (data: AddGenomicVariantParams) => {
    setSaving(true)
    props.reportsService.updateGenomicVariant(data)
      .then(() => {
        modal.current?.dismiss({ wasEdited: true })
      })
      .catch((message) => {
        presentToast({
          message,
          duration: 5000,
        })
      })
      .finally(() => setSaving(false))
  }

  useEffect(() => {
    if (props.genomicVariant) {
      const vafType: 'vaf' | 'amplification' = props.genomicVariant?.amplification_status ? 'amplification' : 'vaf'
      reset({
        ...props.genomicVariant, vaf_type: vafType,
        actionability: props.genomicVariant?.actionability || '',
        mutation: props.genomicVariant?.mutation || '',
        transcript_id: props.genomicVariant?.transcript_id || '',
        coding_sequence_effect: props.genomicVariant?.coding_sequence_effect || '',
        variant_allele_frequency: props.genomicVariant?.variant_allele_frequency || '',
        variant_allele_frequency_value_from_previous_report: props.genomicVariant?.variant_allele_frequency_value_from_previous_report || '',
        amplification_status: props.genomicVariant?.amplification_status || '',
        suspected_germline: props.genomicVariant?.suspected_germline || false
      })
    } else {
      reset({ ...resetValues })
    }
  }, [props.genomicVariant])

  return (
    <IonModal
      className="modal-create-genomic-variant"
      ref={modal}
      isOpen={props.isOpen}
      onDidDismiss={(info) => {
        setType('vaf')
        reset({ ...resetValues })
        if (info.detail.data && info.detail.data.wasEdited) {
          props.setIsOpen(false)
          props.onDidDismiss()
        }
      }}
    >
      <IonContent className="ion-padding modal-edit-report-header">
        <ModalTitle icon={add} label="Add Genomic Variant" />
        <form id="create-genomic-variant-form" onSubmit={handleSubmit(save)}>
          <div className={errors.name ? 'error-field free-text-input' : 'free-text-input'}>
            <span className="label ion-align-items-center">Variant Name <span className="required">*</span></span>
            <IonInput
              placeholder="-"
              required
              {...register('name')}
            />
            {errors.name && <small className="error">{errors.name.message}</small>}
          </div>
          <div className={errors.type ? 'error-field free-text-input' : 'free-text-input'}>
            <span className="label ion-align-items-center">Variant Type <span className="required">*</span></span>
            <IonInput
              placeholder="-"
              required
              {...register('type')}
            />
            {errors.type && <small className="error">{errors.type.message}</small>}
          </div>
          <div className={errors.alteration ? 'error-field free-text-input' : 'free-text-input'}>
            <span className="label ion-align-items-center">Alteration <span className="required">*</span></span>
            <IonInput
              placeholder="-"
              required
              {...register('alteration')}
            />
            {errors.alteration && <small className="error">{errors.alteration.message}</small>}
          </div>
          <div className={errors.actionability ? 'error-field free-text-input' : 'free-text-input'}>
            <span className="label ion-align-items-center">Actionability <span className="required">*</span></span>
            <IonSelect
              placeholder="-"
              required
              {...register('actionability')}
              interface="popover"
            >
              <IonSelectOption value="High">High</IonSelectOption>
              <IonSelectOption value="Medium">Medium</IonSelectOption>
              <IonSelectOption value="Low">Low</IonSelectOption>
            </IonSelect>
            {errors.actionability && <small className="error">{errors.actionability.message}</small>}
          </div>
          <div className={errors.mutation ? 'error-field free-text-input' : 'free-text-input'}>
            <span className="label ion-align-items-center">Mutation <span className="required">*</span></span>
            <IonInput
              placeholder="-"
              required
              {...register('mutation')}
            />
            {errors.mutation && <small className="error">{errors.mutation.message}</small>}
          </div>
          <div className={errors.transcript_id ? 'error-field free-text-input' : 'free-text-input'}>
            <span className="label ion-align-items-center">Transcript ID</span>
            <IonInput
              placeholder="-"
              {...register('transcript_id')}
            />
            {errors.transcript_id && <small className="error">{errors.transcript_id.message}</small>}
          </div>
          <div className={errors.coding_sequence_effect ? 'error-field free-text-input' : 'free-text-input'}>
            <span className="label ion-align-items-center">Coding Sequence Effect</span>
            <IonInput
              placeholder="-"
              {...register('coding_sequence_effect')}
            />
            {errors.coding_sequence_effect && <small className="error">{errors.coding_sequence_effect.message}</small>}
          </div>
          <div className={'free-text-input'}>
            <span className="label ion-align-items-center">Type</span>
            <IonSelect
              value={type}
              onIonChange={(e) => {
                setType(e.target.value as 'vaf' | 'amplification')
                if (e.target.value === 'vaf') {
                  setValue('amplification_status', '')
                } else {
                  setValue('variant_allele_frequency', '')
                  setValue('variant_allele_frequency_value_from_previous_report', '')
                }
                register('vaf_type').onChange(e)
              }}
              {...register('vaf_type')}
            >
              <IonSelectOption value="vaf">VAF</IonSelectOption>
              <IonSelectOption value="amplification">Amplification</IonSelectOption>
            </IonSelect>
          </div>
          {type === 'vaf' && (
            <>
              <div className={errors.variant_allele_frequency ? 'error-field free-text-input' : 'free-text-input'}>
                <span className="label ion-align-items-center">Variant Allele Frequency <span className="required">*</span></span>
                <IonInput

                  placeholder="-"
                  required
                  {...register('variant_allele_frequency')}
                />
                {errors.variant_allele_frequency && <small className="error">{errors.variant_allele_frequency.message}</small>}
              </div>
              <div className={errors.variant_allele_frequency_value_from_previous_report ? 'error-field free-text-input' : 'free-text-input'}>
                <span className="label ion-align-items-center">Variant Allele Frequency Value from Previous Report <span className="required">*</span></span>
                <IonInput

                  placeholder="-"
                  required
                  {...register('variant_allele_frequency_value_from_previous_report')}
                />
                {errors.variant_allele_frequency_value_from_previous_report && <small className="error">{errors.variant_allele_frequency_value_from_previous_report.message}</small>}
              </div>
            </>
          )}
          {type === 'amplification' && (
            <>
              <div className={errors.amplification_status ? 'error-field free-text-input' : 'free-text-input'}>
                <span className="label ion-align-items-center">Amplification Status <span className="required">*</span></span>
                <IonInput

                  placeholder="-"
                  required
                  {...register('amplification_status')}
                />
                {errors.amplification_status && <small className="error">{errors.amplification_status.message}</small>}
              </div>
            </>
          )}
          <IonRow className="ion-align-items-center free-checkbox-input">
            <label htmlFor="suspected-germline">Suspected Germline</label>
            <IonCheckbox
              id="suspected-germline"
              {...register('suspected_germline')}
              onIonChange={(e) => {
                setValue('suspected_germline', e.target.checked)
              }}
            ></IonCheckbox>
          </IonRow>
        </form>
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonButton slot="end" fill="outline" onClick={() => {
            props.setIsOpen(false)
            reset()
          }}>
            Cancel
          </IonButton>

          <IonButton
            slot="end"
            fill="solid"
            disabled={isSaving}
            onClick={handleSubmit(save)}
            form="create-genomic-variant-form"
          >
            {isSaving ? <IonSpinner /> : 'Save'}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default ModalCreateGenomicVariant

