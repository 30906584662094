import { atom } from 'recoil'

export type SignupFormState = {
  name: string
  nameError: string | null
  email: string
  emailError: string | null
  password: string
  passwordError: string | null
  role: string
  roleError: string | null
  clinic: string
  clinicError: string | null
  acceptTerms: boolean
  remoteError: string
  isLoading: boolean
}

export const signupFormState = atom<SignupFormState>({
  key: 'signupFormState',
  default: {
    name: '',
    nameError: null,
    email: '',
    emailError: null,
    password: '',
    passwordError: null,
    role: '',
    roleError: null,
    clinic: '',
    clinicError: null,
    acceptTerms: true,
    remoteError: '',
    isLoading: false,
  },
})
