import { IonCard, IonContent, IonIcon, IonItem, IonLabel, IonPopover } from '@ionic/react'
import { GenomicVariants } from 'domain/usecases/get-report'
import { arrowDown, arrowUp, ellipsisVertical, pencil, searchSharp, star, trash } from 'ionicons/icons'
import { route } from 'main/routes'
import { ButtonRound, Title } from 'presentation/components'
import React from 'react'
import { useHistory, useParams } from 'react-router'
import './genomic-variants-item.scss'

interface GenomicVariantsItemProps {
  item: GenomicVariants
  chart: JSX.Element | JSX.Element[]
  showFrquencyLabel: boolean
  onSearchLink: () => void
  onEdit: () => void
  onDelete: () => void
}

const GenomicVariantsItem: React.FC<GenomicVariantsItemProps> = (props) => {
  const params = useParams<{ id: string }>()
  const navigate = useHistory()

  const goToGeneDetailPage = () => {
    navigate.push(`${route.report.url}/${params.id}/gene/${props.item.id}`)
  }

  return (
    <IonCard className="genomic-item">
      <div className="gi-header-wrapper">
        <div className="gi-header">
          <div className="gi-title">
            <Title size="h3">{props.item.name}</Title>
            {
              props.item.suspected_germline && (
                <IonIcon className="gi-star" icon={star} />
              )
            }

            <IonIcon icon={ellipsisVertical} id={`${props.item.id}-ellipsis`} />
            <IonPopover className='gi-popover-item' mode="ios" trigger={`${props.item.id}-ellipsis`} triggerAction="click">
              <IonContent class="ion-padding">
                <div className='popover-item' onClick={props.onSearchLink}>
                  <IonIcon icon={searchSharp} />
                  <IonLabel>Search</IonLabel>
                </div>
                <div className='popover-item' onClick={props.onEdit}>
                  <IonIcon icon={pencil} />
                  <IonLabel>Edit</IonLabel>
                </div>
                <div className='popover-item' onClick={props.onDelete}>
                  <IonIcon icon={trash} />
                  <IonLabel>Delete</IonLabel>
                </div>
              </IonContent>
            </IonPopover>
          </div>
          <div className="gi-subtitle-wrapper">
            <small className="gi-subtitle">{props.item.alteration}</small>
          </div>
          {
            props.item.amplification_status && (
              <div className="gi-subtitle-wrapper">
                <small className="gi-percentage">Amplification Status: {props.item.amplification_status}</small>
              </div>
            )
          }
          {props.item.variant_allele_frequency && (
            <div className="gi-vaf-wrapper">
              <small className="gi-percentage">VAF {props.item.variant_allele_frequency}</small>
              {
                props.item.variant_allele_frequency_value_from_previous_report &&
                (
                  <>
                    {props.item.variant_allele_frequency_value_from_previous_report > props.item.variant_allele_frequency ? (
                      <IonIcon icon={arrowDown} color="success" />
                    ) : (
                      <IonIcon icon={arrowUp} color="danger" />
                    )}
                  </>
                )
              }
              <small className="gi-percentage">
                {
                  props.item.variant_allele_frequency_value_from_previous_report && (
                    `(${props.item.variant_allele_frequency_value_from_previous_report} previously)`
                  )
                }
              </small>
            </div>
          )}
        </div>

        {props.item.actionability ? (
          <div className="gi-action">
            Actionability: <span className={props.item.actionability}>{props.item.actionability}</span>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className="gi-bottom">
        {props.showFrquencyLabel ? <span className="chart-title">Frequency by Cancer Type</span> : undefined}
        {props.chart}

        <div className="no-printing">
          <ButtonRound size="small" expand="block" onClick={goToGeneDetailPage}>
            More Info
          </ButtonRound>
        </div>
      </div>
    </IonCard>
  )
}

export default GenomicVariantsItem
