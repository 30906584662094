import React from 'react'
import './viewport.scss'

type ViewportProps = {
  className?: string
  id?: string
}

const Viewport: React.FC<ViewportProps> = (props) => {
  return (
    <div className={`viewport-wrapper ${props.className}`} id={props.id}>
      <div className="viewport">{props.children}</div>
    </div>
  )
}

export default Viewport
