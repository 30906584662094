import { IonFooter } from '@ionic/react'
import React from 'react'
import './footer.scss'

interface FooterProps {
  slot?: JSX.Element
}

const Footer: React.FC<FooterProps> = (props) => {
  return (
    <IonFooter className="app-footer">
      {props.slot}

      <>
        Powered By{' '}
        <a href="https://arionkoder.com/" target="_blank" rel="noopener noreferrer">
          Arionkoder
        </a>
      </>
    </IonFooter>
  )
}

export default Footer
