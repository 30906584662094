import { PatientStatus } from 'domain/usecases/list-patients'
import { toStatus } from 'domain/usecases/list-patients'
import { TypeaheadItem } from 'presentation/components/typeahead/typeahead'
import { atom } from 'recoil'
import { ModalEditReportHeaderState } from '../modal-edit-report-header/modal-edit-report-header-atom'

export interface ModalEditPatientState {
  mrn: string | null
  cancerType: TypeaheadItem
  stages: TypeaheadItem
  status: TypeaheadItem
  priorTreatments: string | null
  payer: TypeaheadItem
  comorbidities: string | null
  address: string | null
  insurance_id_number: string | null
  group_id_number: string | null
}

export const modalEditPatientAtom = atom<ModalEditPatientState>({
  key: 'modalEditPatientAtom',
  default: {
    mrn: null,
    cancerType: null,
    stages: null,
    status: null,
    priorTreatments: null,
    payer: null,
    comorbidities: null,
    address: null,
    insurance_id_number: null,
    group_id_number: null
  },
})


export const reportToTypeaheadItem = (patient: ModalEditPatientState) => {
  return {
    cancerType: {
      label: patient?.cancerType?.label as string,
      value: patient?.cancerType?.value as string,
    },
    stages: {
      label: patient?.stages?.label as string,
      value: patient?.stages?.value as string,
    },
    status: {
      label: toStatus[patient?.status?.value as PatientStatus] as string,
      value: patient?.status?.value as string,
    },
    comorbidities: patient.comorbidities,
    payer: {
      label: patient?.payer?.label as string,
      value: patient?.payer?.value as string,
    },
    priorTreatments: patient.priorTreatments,
    address: patient.address,
    group_id_number: patient.group_id_number,
    insurance_id_number: patient.insurance_id_number
  } as ModalEditReportHeaderState
}
