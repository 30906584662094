export const baseUrl = process.env.REACT_APP_API_URL as string

export const clinicsUrl = {
  getClinics: '/v1/users/clinics',
}

export const rolesUrl = {
  getRoles: '/v1/users/roles',
}

export const patientsUrl = {
  base: '/v1/patients',
}

export const entitiesUrl = {
  base: '/v1/entities',
}

export const reportsUrl = {
  base: '/v1/reports',
  extracted: '/v1/reports/extracted',
}

export const exploreUrl = {
  base: '/v1/explore',
}

export const insightsUrl = {
  base: '/v1/insights',
}

export const usersUrl = {
  base: '/v1/users',
}
