import { PatientItem } from 'domain/usecases/list-patients'
import { chevronForwardOutline, documentTextOutline } from 'ionicons/icons'
import { route } from 'main/routes'
import { FlexGrid } from 'presentation/components'
import ActionItem from 'presentation/components/action-item/action-item'
import MarkerRead from 'presentation/components/marker-read/marker-read'
import React from 'react'
import { useHistory } from 'react-router'
import './patients-item.scss'

type PatientsItemProps = {
  patientItem: PatientItem
  index: number
  onClick: () => void
}

export const PatientsItem: React.FC<PatientsItemProps> = (props) => {
  const navigate = useHistory()

  return (
    <tr className={`patients-item ${props.patientItem.read === false ? 'not-read' : ''}`}>
      <td className="pi-marker-flex">
        <MarkerRead read={props.patientItem.read} />
        <div>
          <b>{props.patientItem.name}</b>
          <br />
          <small>MRN: {props.patientItem.MRN}</small>
        </div>
      </td>
      <td>
        <span>{props.patientItem.cancer_type}</span>
      </td>
      <td>
        <span>{props.patientItem.treatment || '-'}</span>
      </td>
      <td>
        <span>{props.patientItem.status || '-'}</span>
      </td>
      <td>
        <FlexGrid gap={4}>
          <ActionItem
            tooltip="Last Report"
            icon={documentTextOutline}
            onClick={() => {
              navigate.push(`${route.report.url}/${props.patientItem.last_report_id}`, props.patientItem)
              props.onClick()
            }}
          />

          <ActionItem
            icon={chevronForwardOutline}
            tooltip="Patient Details"
            onClick={() => {
              navigate.push(`${route.patient.url}`, {
                patientItem: props.patientItem,
              })
              props.onClick()
            }}
          />
        </FlexGrid>
      </td>
    </tr>
  )
}

export default PatientsItem
