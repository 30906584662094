import { IonButton, IonIcon } from '@ionic/react'
import { Patient } from 'domain/usecases/get-patient'
import { toStatus } from 'domain/usecases/list-patients'
import { pencilOutline } from 'ionicons/icons'
import React from 'react'
import DefaultCard from '../default-card/default-card'
import './patient-information.scss'

interface PatientInformationItemProps {
  title: string
  value: string
}

const PatientInformationItem: React.FC<PatientInformationItemProps> = (props) => {
  return (
    <div className="pi-item">
      <small>{props.title}</small>
      <br />
      <small>
        <b>{props.value}</b>
      </small>
    </div>
  )
}

interface PatientInformationProps {
  patientItem: Patient
  isLoading: boolean
  onEditPatientInformation: () => void
}

const PatientInformation: React.FC<PatientInformationProps> = (props) => {
  const fallback = 'Unknown'

  return (
    <DefaultCard title="Patient information" isLoading={props.isLoading}>
      <div className="patient-information-card">
        <PatientInformationItem title="CANCER TYPE" value={props.patientItem.cancer_type || fallback} />
        <PatientInformationItem title="STAGE" value={props.patientItem.cancer_stage || fallback} />
        <PatientInformationItem title="PRIOR TREATMENTS" value={props.patientItem.prior_treatments || '-'} />
        <PatientInformationItem title="Payer" value={props.patientItem.payer || fallback} />
        <PatientInformationItem title="Co-morbidities" value={props.patientItem.co_morbidities || '-'} />
        <PatientInformationItem title="Status" value={toStatus[props.patientItem.status] || 'Unknown'} />
        <PatientInformationItem title="Insurance ID Number" value={props.patientItem.insurance_id_number || '-'} />
        <PatientInformationItem title="Group ID Number" value={props.patientItem.group_id_number || '-'} />
        <PatientInformationItem title="Address" value={props.patientItem.address || '-'} />
        <IonButton size="small" expand="block" onClick={props.onEditPatientInformation}>
          <IonIcon icon={pencilOutline} slot="end" />
          Edit
        </IonButton>
      </div>
    </DefaultCard>
  )
}

export default PatientInformation
