import { PatientStatus } from './list-patients'

export interface GenomicVariants {
  id: number
  actionability: string | null
  alteration: string
  name: string
  type: string
  amplification_status: string | null
  coding_sequence_effect: string | null
  mutation: string | null
  suspected_germline: boolean
  transcript_id: string | null
  variant_allele_frequency: string
  variant_allele_frequency_value_from_previous_report: string | null
}

export interface TreatmentDetail {
  description: string[] | null
  phase: string
  locations: string[]
  status: string | null
  link: string
  prescription: {
    drug: string
    image_url: string
    url: string
  }
}
export interface Treatment {
  id: number
  name: string
  type: string
  genes: string[]
  detail: TreatmentDetail
  relevance: string | null
  reason: string
  selected: boolean
}

export enum ReportStatus {
  processing = 'processing',
  processed = 'processed',
  failed = 'failed',
}

export type SelectedTreatments = {
  note: string
  treatments: Treatment[]
}

export interface ReportPatient {
  name: string | null
  MRN: string | null
  changed: string | null
  cancer_type: string | null
  cancer_stage: string | null
  birthdate: string | null
  age: number | null
  sex: string | null
  status: PatientStatus
  treatment: string | null
  prior_treatments: string | null
  payer: string | null
  co_morbidities: string | null
  insurance_id_number: string | null
  group_id_number: string | null
  address: string | null
  emr_link: string | null
  read: boolean
  last_report_id: string | null
}

export interface Report {
  user_id: string
  created_at: string
  lab: string
  status: ReportStatus
  report_id: string
  lab_issued_id: string
  report_date: string
  biopsy_date: string
  patient: ReportPatient
  diagnosis: string
  tumor_specimen: string
  specimen_type: string
  emr_link: string
  tumor_mutational_burden: string
  read: boolean
  tmb_percentile: string
  doctor_name: string
  microsatellite_instability_status: string
  ct_dna_tumor_fraction: string
  hrd_signature: string | null
  genomic_variants: GenomicVariants[]
  treatments: Treatment[]
  presigned_url: {
    presigned_url: string,
    key: string
  }
  selected_treatments: {
    note: string
    treatments: Treatment[]
  }
}

export interface GetReportParams {
  reportId: string
}

export interface GetReport {
  getReport: (params: GetReportParams) => Promise<Report>
}
