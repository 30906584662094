import { EmailValidation } from 'main/validations/email-validation'
import { GetValidationError } from 'main/validations/get-validation-error'
import { RequiredValidation } from 'main/validations/required-validation'
import { ForgotPasswordValidation } from 'main/validations/usecases/forgot-password-validation'

export const makeForgotPasswordValidations = (): ForgotPasswordValidation => {
  return {
    email: [new RequiredValidation(), new EmailValidation()],
    getValidationError: new GetValidationError().getValidationError,
  }
}
