import { atom } from 'recoil'

type ResetPasswordState = {
  code: string
  remoteError: string
  password: string
  passwordError: string | null
  isLoading: boolean
}

export const resetPasswordState = atom<ResetPasswordState>({
  key: 'resetPasswordState',
  default: {
    code: '',
    remoteError: '',
    password: '',
    passwordError: null,
    isLoading: false,
  },
})
