import ReportPage from 'presentation/pages/report/report'
import React from 'react'
import { makeInsightsService } from '../services/insights-service-factory'

import { makePatientsService } from '../services/patients-service-factory'
import { makeReportsService } from '../services/reports-service-factory'
import { makeUserInternalService } from '../services/user-internal-service-factory'
import { makeUserService } from '../services/user-service-factory'

const userService = makeUserService()
const userInternalService = makeUserInternalService()
const reportsService = makeReportsService()
const patientsService = makePatientsService()
const insightsService = makeInsightsService()

const makeReportPage: React.FC = () => {
  return (
    <ReportPage
      userService={userService}
      reportsService={reportsService}
      patientsService={patientsService}
      insightsService={insightsService}
      userInternalService={userInternalService}
    />
  )
}

export default makeReportPage
