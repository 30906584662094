/* eslint-disable react/prop-types */
import { IonLabel } from '@ionic/react'
import debounce from 'lodash.debounce'
import React, { useCallback } from 'react'
import Select from 'react-select'
import './typeahead.scss'

type TypeaheadProps = React.ComponentPropsWithoutRef<Select> & {
  placeholder: string
  label: string
  onTypeaheadDebounce: (valeu: string) => void
}

export type TypeaheadItem = {
  label: string | null
  value: string | null
} | null

export interface GroupedOption {
  readonly label: string
  readonly options: readonly TypeaheadItem[]
}

const Typeahead: React.FC<TypeaheadProps> = (props) => {
  const debounceFn = useCallback(debounce(props.onTypeaheadDebounce as (valeu: string) => void, 500), [])

  const handleChange = (value: string) => {
    debounceFn(value)
  }

  return (
    <div className="typeahead-item">
      <IonLabel position="stacked">{props.label}</IonLabel>
      <Select
        className="typeahead"
        classNamePrefix="typeahead"
        styles={{
          control: () => ({
            border: 0,
            boxShadow: '0',
            display: 'flex',
            borderRadius: 0,
            margin: 0,
            padding: 0,
            height: '30px',
            paddingLeft: '6px',
          }),
          option: (_, state) => ({
            backgroundColor: state.isSelected ? '' : '',
            padding: '4px 8px',
          }),
          indicatorSeparator: () => ({ display: 'none' }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        menuPortalTarget={document.body}
        noOptionsMessage={() => 'No options found'}
        onInputChange={(value) => handleChange(value)}
        {...props}
      />
    </div>
  )
}

export default Typeahead
