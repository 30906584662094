import { IonButton, IonIcon, IonModal, IonSpinner } from '@ionic/react'
import { ReportStatus } from 'domain/usecases/get-report'
import { NotFound, Title } from 'presentation/components'
import React, { useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { reportAtom } from '../../report-atom'
import './immunotherapy-markers.scss'
import { ReportsService } from 'infra/services/reports-service'
import { add } from 'ionicons/icons'
import ModalEditReportHeader from '../modal-edit-report-header/modal-edit-report-header'
import { ReportEdition } from '../modal-edit-report-header/modal-edit-report-header'

interface ImmunotherapyMarkersProps {
  options: {
    tumor_mutational_burden: string | undefined
    tmb_percentile: string | undefined
    microsatellite_instability_status: string | undefined
    ct_dna_tumor_fraction: string | undefined
    lab: string | undefined
    hrd_signature: string | null
  }
  reportsService: ReportsService
  onDismiss: () => void
}

export const ImmunotherapyMarkers: React.FC<ImmunotherapyMarkersProps> = (props) => {
  const { isLoadingReport, report } = useRecoilValue(reportAtom)
  const [isModalEditOpen, setIsModalEditOpen] = useState(false)
  const modalEdit = useRef<HTMLIonModalElement>(null)
  return (
    <section className="immunotherapy-markers-section">
      {isLoadingReport ? (
        <div className="im-spinner">
          <IonSpinner name="dots" />
        </div>
      ) : (
        <>
          <Title size="h2" className="first-line">
            Additional Biomarkers
          </Title>
          {report?.status === ReportStatus.processing ? (
            <NotFound>The report is being processed</NotFound>
          ) : (
            <>
              <div className="innercard">
                <div className="tumor-mutational">
                  <div className="tm-title">
                    <b>Tumor Mutational Burden</b>
                    <div className="chip">
                      <span>{props.options.tumor_mutational_burden || 'Unknown'}</span>
                      {
                        props.options.tmb_percentile && (
                          <span className={'chip-info'}>
                            {props.options.tmb_percentile}
                          </span>
                        )
                      }
                    </div>
                  </div>
                </div>

                <div className="tumor-mutational">
                  <div className="tm-title">
                    <b>Microsatellite Instability Status</b>
                    <div className={'chip'}>{props.options.microsatellite_instability_status || 'Unknown'}</div>
                  </div>
                </div>
                {props.options.ct_dna_tumor_fraction && (
                  <div className="tumor-mutational">
                    <div className="tm-title">
                      <b>CT DNA Tumor Fraction</b>
                      <div className={'chip'}>{props.options.ct_dna_tumor_fraction || 'Unknown'}</div>
                    </div>
                  </div>
                )}

                {props.options.hrd_signature && (
                  <div className="hrd_signature">
                    <div className="tm-title">
                      <b>HRD Signature</b>
                      <div className={'chip'}>{props.options.hrd_signature || 'Unknown'}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="immunotherapy-markers-actions">
                <IonButton
                  size="small"
                  fill="outline"
                  onClick={() => setIsModalEditOpen(true)}>
                  <IonIcon icon={add} />
                  Edit Immunotherapy Markers
                </IonButton>
              </div>
              {report && (
                <IonModal
                  className="modal-edit-report"
                  ref={modalEdit}
                  isOpen={isModalEditOpen}
                  onDidDismiss={(info) => {
                    if (info.detail.data && info.detail.data.wasEdited) props.onDismiss()
                    setIsModalEditOpen(false)
                  }}
                >

                  {report ? (
                    <>
                      <ModalEditReportHeader
                        modal={modalEdit}
                        reportsService={props.reportsService}
                        headerTitle="Edit Immunotherapy Markers"
                        reportEdition={
                          {
                            reportID: report?.report_id,
                            biopsyDate: report?.biopsy_date,
                            specimen: report?.tumor_specimen,
                            specimenType: report?.specimen_type,
                            reportDate: report?.report_date,
                            genomicVariants: report?.genomic_variants,
                            treatments: report?.treatments,
                            tumor_mutational_burden: report?.tumor_mutational_burden,
                            tmb_percentile: report?.tmb_percentile,
                            microsatellite_instability_status: report?.microsatellite_instability_status,
                            ct_dna_tumor_fraction: report?.ct_dna_tumor_fraction,
                            hrd_signature: report?.hrd_signature,
                          } as ReportEdition
                        }
                        fields={{
                          reportID: true,
                          tumor_mutational_burden: true,
                          tmb_percentile: true,
                          microsatellite_instability_status: true,
                          ct_dna_tumor_fraction: true,
                          hrd_signature: true,
                        }}
                      />
                    </>
                  ) : undefined}
                </IonModal>
              )}
            </>
          )}
        </>
      )}
    </section>
  )
}
