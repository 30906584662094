import { FavoriteArticle, FavoriteArticleParams } from 'domain/usecases/favorite-article'
import {
  GetExploreResults,
  GetExploreResultsParams,
  GetExploreResultsParamsDates,
  GetExploreResultsResponse,
} from 'domain/usecases/get-explore-results'
import { exploreUrl } from 'infra/config/aws-env'
import { HttpClient } from 'infra/http/usecases/http-client'
import moment from 'moment'

export class ExploreService implements GetExploreResults, FavoriteArticle {
  constructor(private baseUrl: string, private httpClient: HttpClient) {}

  getExploreResults(params: GetExploreResultsParams): Promise<GetExploreResultsResponse> {
    const dates = this.getDates(params.dates)

    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          type: 'post',
          headers: {
            'Content-type': 'application/json',
          },
          url: `${this.baseUrl}${exploreUrl.base}/${params.tab}?page=${params.page}&size=${params.size}`,
          body: {
            search: params.search,
            cancer_type: params.cancer_type,
            treatment: params.treatment,
            order_by: params.order_by,
            ...(dates.from_date ? { from_date: dates.from_date } : {}),
            ...(dates.to_date ? { to_date: dates.to_date } : {}),
          },
        })
        .then((data) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }

  private getDates(params: GetExploreResultsParamsDates) {
    const today = moment().toDate()

    type PossibleDates = {
      [key in GetExploreResultsParamsDates]: { from_date: Date; to_date: Date }
    }

    const possibleDates: PossibleDates = {
      'less-than-a-year': {
        from_date: moment().subtract(1, 'year').toDate(),
        to_date: today,
      },
      'less-than-6-months': {
        from_date: moment().subtract(6, 'months').toDate(),
        to_date: today,
      },
    }

    if (params) {
      return possibleDates[params]
    } else {
      return {
        from_date: '',
        to_date: '',
      }
    }
  }

  favoriteArticle(params: FavoriteArticleParams): Promise<string> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          type: 'put',
          headers: {
            'Content-type': 'application/json',
          },
          url: `${this.baseUrl}${exploreUrl.base}/${params.articleId}/starred`,
          body: {
            starred: params.starred,
          },
        })
        .then((data) => {
          resolve(data)
        })
        .catch((err) => reject(err))
    })
  }
}
