export const route = {
  login: {
    url: '/login',
    sub: {},
  },
  signUp: {
    url: '/signup',
    sub: {},
  },
  forgotPassword: {
    url: '/forgot-password',
    sub: {},
  },
  resetPassword: {
    url: '/reset-password',
    sub: {},
  },
  dashboard: {
    url: '/dashboard',
    sub: {},
  },
  report: {
    url: '/report',
    sub: {
      id: ':id',
      gene: 'gene/:gene',
    },
  },
  explore: {
    url: '/explore',
  },
  patient: {
    url: '/patient',
  },
}
