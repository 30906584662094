import { UserInfo } from 'infra/services/usecases/get-user-info'
import { makeCacheLocalStorage } from 'main/factories/cache/cache-local-storage-factory'
import { makeAccessTokenCache } from 'main/factories/cache/set-access-token-cache-factory'

export const setCurrentAccountAdapter = (account: UserInfo): void => {
  makeCacheLocalStorage().set('account', JSON.stringify(account))
}

export const clearCurrentAccountAdapter = (): void => {
  makeAccessTokenCache().set('')
  makeCacheLocalStorage().remove()
}

export const getCurrentAccountAdapter = (): UserInfo => {
  const currentAccount = makeCacheLocalStorage().get('account')

  return currentAccount ? JSON.parse((currentAccount as string) || '') : undefined
}
