import { GeneInfoResponse, GetGenesInfo, GetGenesInfoParams } from 'domain/usecases/get-genes-info'
import { insightsUrl } from 'infra/config/aws-env'
import { HttpClient } from 'infra/http/usecases/http-client'

export class InsightsService implements GetGenesInfo {
  constructor(private baseUrl: string, private httpClient: HttpClient) {}

  getGenesInfo(params: GetGenesInfoParams): Promise<GeneInfoResponse> {
    return new Promise((resolve, reject) => {
      this.httpClient
        .request({
          type: 'get',
          headers: {
            'Content-type': 'application/json',
          },
          url: `${this.baseUrl}${insightsUrl.base}/cancer-type-frequency?genes=${params.genes.join(',')}`,
        })
        .then((response) => {
          resolve(response as GeneInfoResponse)
        })
        .catch((error) => reject(error))
    })
  }
}
