import { ForgotPassword } from 'domain/usecases/forgot-password'
import { RemoteGetUserCognito } from './remote-get-user-cognito'

export class RemoteForgotPasswordCognito implements ForgotPassword {
  private cognitoUser = new RemoteGetUserCognito()

  sendResetLink(username: string) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.cognitoUser.getCognitoUser(username)

      if (!cognitoUser) {
        reject(`Could not find ${username}`)
        return
      }

      cognitoUser.forgotPassword({
        onSuccess: (res) => resolve(res),
        onFailure: (err) => {
          if (err && err.name === 'UserNotFoundException') resolve(err)
          reject(err)
        },
      })
    }).catch((err) => {
      throw err
    })
  }
}
