import { IonButton, IonIcon, IonSkeletonText, IonSpinner } from '@ionic/react'
import { GeneInfoItem } from 'domain/usecases/get-genes-info'
import { GenomicVariants, ReportStatus } from 'domain/usecases/get-report'
import { route } from 'main/routes'
import { ButtonRound, FlexGrid, MutationAmplification, NotFound, Title } from 'presentation/components'
import ChartBar from 'presentation/components/chart-bar/chart-bar'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue } from 'recoil'
import { reportAtom } from '../../report-atom'
import GenomicVariantsItem from './genomic-variants-item'
import './genomic-variants.scss'
import ModalCreateGenomicVariant from '../modal-create-genomic-variant/modal-create-genomic-variant'
import { ReportsService } from 'infra/services/reports-service'
import { add, star } from 'ionicons/icons'

interface GenomicVariantsProps {
  reportsService: ReportsService
  onUpdateGenomicVariants: () => void
}

const GenomicVariantList: React.FC<GenomicVariantsProps> = (props) => {
  const { report, isLoadingReport, genesInfo, isLoadingGenes } = useRecoilValue(reportAtom)
  const [genomicVariantsShow, setGenomicVariantsShow] = useState(report?.genomic_variants)
  const qtyToShow = 4
  const [showShowMore, setShowShowMore] = useState<null | boolean>(null)
  const hasGenomicVariants = report?.genomic_variants && report.genomic_variants.length
  const navigate = useHistory()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [genomicVariantToEdit, setGenomicVariantToEdit] = useState<GenomicVariants | null>(null)

  useEffect(() => {
    setGenomicVariantsShow(report?.genomic_variants?.slice(0, qtyToShow))
  }, [report?.genomic_variants])

  return (
    <section className="genomic-variants">
      {report?.status === ReportStatus.processing && !isLoadingReport ? (
        <div className="gv-processing">
          <NotFound>The report is being processed</NotFound>
        </div>
      ) : (
        <>
          {isLoadingReport ? (
            <div className="gv-grid">
              <IonSkeletonText animated style={{ height: '130px', width: '170px' }} />
              <IonSkeletonText animated style={{ height: '130px', width: '170px' }} />
              <IonSkeletonText animated style={{ height: '130px', width: '170px' }} />
              <IonSkeletonText animated style={{ height: '130px', width: '170px' }} />
            </div>
          ) : (
            <>
              <FlexGrid justifyContent="space-between">
                <Title size="h2" className="gv-custom-title">
                  Genomic Variants
                </Title>
                <FlexGrid gap={16}>
                  <MutationAmplification />
                </FlexGrid>
              </FlexGrid>
              <div className="gv-grid">
                {genomicVariantsShow &&
                  genomicVariantsShow.map((variant, index) => (
                    <GenomicVariantsItem
                      item={variant}
                      key={index}
                      onDelete={() => {
                        if (variant.id && report?.report_id) {
                          props.reportsService.deleteGenomicVariant({
                            genomic_variant_id: variant.id.toString(),
                            report_id: report?.report_id,
                          })
                            .then(() => {
                              props.onUpdateGenomicVariants()
                            })
                        }
                      }}
                      onSearchLink={() => {
                        navigate.push(route.explore.url, {
                          geneName: variant.name,
                          cancerType: report?.patient.cancer_type || report?.diagnosis,
                        })
                      }}
                      onEdit={() => {
                        setGenomicVariantToEdit(variant)
                        setIsModalOpen(true)
                      }}
                      showFrquencyLabel={!!(genesInfo && genesInfo[variant.name] && genesInfo[variant.name].length)}
                      chart={
                        isLoadingGenes ? (
                          <FlexGrid justifyContent={'center'}>
                            <IonSpinner name="dots" />
                          </FlexGrid>
                        ) : genesInfo && genesInfo[variant.name] ? (
                          genesInfo[variant.name].map((chartItem, key) => (
                            <ChartBar key={key} textInTheBar={true} geneInfo={chartItem as GeneInfoItem} />
                          ))
                        ) : (
                          <></>
                        )
                      }
                    />
                  ))}
              </div>
            </>
          )}
          {
            report?.genomic_variants?.some((variant) => variant.suspected_germline) && (
              <div className="gv-legend">
                <IonIcon className="gi-star" icon={star} /> Suspected germline variant
              </div>
            )
          }

          {!genomicVariantsShow ||
            (!genomicVariantsShow.length && !isLoadingReport && (
              <div className="gv-processing">
                <NotFound>No Genomic Variants available for the patient</NotFound>
              </div>
            ))}
          {!isLoadingReport && (
            <div className="gv-actions">
              {((hasGenomicVariants as number) > qtyToShow && showShowMore !== false) &&
                <div className="gv-show-more">
                  <ButtonRound
                    onClick={() => {
                      setGenomicVariantsShow(report?.genomic_variants)
                      setShowShowMore(false)
                    }}
                    size="small"
                  >
                    <IonIcon icon={add} />
                    More Genomic Variants
                  </ButtonRound>
                </div>
              }
              <IonButton
                fill="outline"
                size="small"
                className="gv-add-variant"
                onClick={() => {
                  setGenomicVariantToEdit(null)
                  setIsModalOpen(true)
                }}
              >
                <IonIcon icon={add} />
                Add Genomic Variant
              </IonButton>
            </div>
          )}
        </>
      )}
      {
        report && (
          <ModalCreateGenomicVariant
            reportId={report?.report_id}
            reportsService={props.reportsService}
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            onDidDismiss={() => {
              props.onUpdateGenomicVariants()
              setGenomicVariantToEdit(null)
            }}
            genomicVariant={genomicVariantToEdit}
          />
        )}
    </section>
  )
}

export default GenomicVariantList
