import { EmailValidation } from 'main/validations/email-validation'
import { GetValidationError } from 'main/validations/get-validation-error'
import { RequiredValidation } from 'main/validations/required-validation'
import { LoginFormValidation } from 'main/validations/usecases/login-form-validation'

export const makeLoginFormValidations = (): LoginFormValidation => {
  const makeRequiredValidation = new RequiredValidation()

  return {
    email: [makeRequiredValidation, new EmailValidation()],
    getValidationError: new GetValidationError().getValidationError,
  }
}
