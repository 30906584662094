import { LoginAccount } from 'domain/usecases/login-account'
import { SignupAccount } from 'domain/usecases/signup-account'
import { ClinicsService } from 'infra/services/clinics-service'
import { RolesService } from 'infra/services/roles-service'
import { route } from 'main/routes'
import { SignupFormValidation } from 'main/validations/usecases/signup-form-validation'
import { Background, CardBackground } from 'presentation/components'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useRecoilState, useResetRecoilState } from 'recoil'
import { signupFormState } from './organism/signup-atom'
import SignupForm from './organism/signup-form'

type SignupPageProps = {
  authSignup: SignupAccount
  authLogin: LoginAccount
  validations: SignupFormValidation
  clinicsService: ClinicsService
  rolesService: RolesService
}

const SignupPage: React.FC<SignupPageProps> = (props) => {
  const [formGroup, setFormGroup] = useRecoilState(signupFormState)
  const navigate = useHistory()
  const resetState = useResetRecoilState(signupFormState)

  const loginAfterSignup = (params: { email: string; password: string }) => {
    props.authLogin
      .loginAccount(params)
      .then(() => navigate.push(route.dashboard.url))
      .catch(() => navigate.push(route.login.url))
  }

  useEffect(() => {
    return () => resetState()
  }, [])

  const onSubmit = () => {
    setFormGroup((current) => ({ ...current, isLoading: true }))
    props.authSignup
      .signupAccount({
        email: formGroup.email,
        name: formGroup.name,
        password: formGroup.password,
        role: formGroup.role,
        clinic: formGroup.clinic,
      })
      .then(() => {
        setFormGroup((current) => ({ ...current, remoteError: '' }))
        loginAfterSignup({ email: formGroup.email, password: formGroup.password })
      })
      .catch((error) => {
        setFormGroup((current) => ({ ...current, remoteError: error.message }))
      })
      .finally(() => setFormGroup((current) => ({ ...current, isLoading: false })))
  }

  return (
    <Background>
      <CardBackground className="signup-card">
        <SignupForm
          onSubmit={onSubmit}
          validations={props.validations}
          clinicsService={props.clinicsService}
          rolesService={props.rolesService}
        />
      </CardBackground>
    </Background>
  )
}

export default SignupPage
