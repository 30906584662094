import { atom } from 'recoil'

type ForgotPasswordState = {
  email: string
  emailError: string | null
  isLoading: boolean
}

export const forgotPasswordState = atom<ForgotPasswordState>({
  key: 'forgotPasswordState',
  default: {
    email: '',
    emailError: null,
    isLoading: false,
  },
})
