import { IonContent, IonIcon, IonLabel, IonPopover } from '@ionic/react'
import { UserService } from 'infra/services/user-service'
import { caretDownOutline } from 'ionicons/icons'
import { route } from 'main/routes'
import { ButtonLink } from 'presentation/components'
import { currentAccountState } from 'presentation/hooks/current-account-atom'
import { headingAtom } from 'presentation/pages/dashboard/organisms/heading/heading-atom'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import './header.scss'
import { makeCacheLocalStorage } from 'main/factories/cache/cache-local-storage-factory'

interface HeaderProps {
  userService: UserService
  tab: 'patients' | 'explore'
  injectedQuery?: string
}

export const Header: React.FC<HeaderProps> = (props) => {
  const accountState = useRecoilValue(currentAccountState).getCurrentAccount()
  const navigate = useHistory()
  const headingState = useResetRecoilState(headingAtom)

  const logout = () => {
    headingState()
    props.userService.logout(() => navigate.replace(route.login.url))
  }

  const goTo = (str: string) => {
    navigate.push(str)
  }

  const navigateWithCachePatient = () => {
    goTo(`${route.explore.url}?search=${props.injectedQuery || ''}`)
    const localStorage = makeCacheLocalStorage()

    if (props.injectedQuery && props.injectedQuery.length) localStorage.set('cached-cancer-type', props.injectedQuery)
  }

  return (
    <section className="header-toolbar">
      <div className="header-toolbar-logo">
        <div className="logo" onClick={() => navigate.replace(route.dashboard.url)}>
          <b>
            Onco
            <span className="blue">Rx</span>
          </b>{' '}
          <span>Insights</span>
        </div>
      </div>

      <div className="header-toolbar-segment">
        <ButtonLink
          color={props.tab === 'patients' ? 'primary' : 'dark'}
          noLink={true}
          onClick={() => goTo(route.dashboard.url)}
        >
          <b>Patients</b>
        </ButtonLink>
        <ButtonLink
          color={props.tab === 'explore' ? 'primary' : 'dark'}
          noLink={true}
          onClick={() => navigateWithCachePatient()}
        >
          <b>Explore</b>
        </ButtonLink>
      </div>

      <div className="header-toolbar-popover" id="context-header-popover">
        <div>
          <IonLabel color="dark">
            <b>{accountState?.name}</b>
          </IonLabel>
          <br />
          <small>{accountState?.role}</small>
        </div>
        <IonIcon icon={caretDownOutline}></IonIcon>
      </div>

      <IonPopover trigger="context-header-popover" triggerAction="click" showBackdrop={false} dismissOnSelect={true}>
        <IonContent class="ion-padding">
          <ButtonLink color="dark" onClick={logout} noLink={true}>
            Log Out
          </ButtonLink>
        </IonContent>
      </IonPopover>
    </section>
  )
}
