import { eye, eyeOff } from 'ionicons/icons'
import React, { useState } from 'react'
import ButtonIcon from '../buttons/button-icon'
import Input from './input'
import './input-password.scss'

type PasswordInputProps = React.ComponentPropsWithRef<typeof Input>

enum PasswordInputTypes {
  SHOW = 'text',
  HIDE = 'password',
}

const PasswordInput: React.FC<PasswordInputProps> = (props: PasswordInputProps) => {
  const [inputProps, setInputProps] = useState({
    icon: eye,
    inputType: PasswordInputTypes.HIDE,
  })

  const showHidePassword = () => {
    const shouldShow = inputProps.inputType === PasswordInputTypes.HIDE
    const inputType = shouldShow ? PasswordInputTypes.SHOW : PasswordInputTypes.HIDE
    const icon = shouldShow ? eyeOff : eye

    setInputProps({ icon, inputType })

    return false
  }

  return (
    <Input
      {...props}
      buttonRight={
        <ButtonIcon
          fill="clear"
          className="right-button"
          slot="end"
          size="small"
          icon={inputProps.icon}
          onClick={showHidePassword}
        />
      }
      type={inputProps.inputType}
    />
  )
}

export default PasswordInput
