import { LoginAccount } from 'domain/usecases/login-account'
import { AccessTokenCache } from 'infra/cache/set-access-token-cache'
import { GetUserInfo } from 'infra/services/usecases/get-user-info'
import { setCurrentAccountAdapter } from 'main/adapters/current-account-adapter'

export class LoginAccountService implements LoginAccount {
  constructor(private remote: LoginAccount, private cache: AccessTokenCache, private userInfoService: GetUserInfo) {}

  setAccessToken(value: string) {
    this.cache.set(value)
  }

  updateCurrentAccount(): Promise<void> {
    return new Promise((resolve) => {
      this.userInfoService.getUserInfo().then((info) => {
        setCurrentAccountAdapter(info)
        resolve()
      })
    })
  }

  loginAccount(params: LoginAccount.Params): Promise<LoginAccount.Model> {
    return new Promise((resolve, reject) => {
      this.remote
        .loginAccount(params)
        .then((info) => {
          this.setAccessToken(info.token)
          this.updateCurrentAccount().then(() => resolve(info))
        })
        .catch((errors) => {
          reject(errors)
        })
    })
  }
}
