import { GeneInfoItem } from 'domain/usecases/get-genes-info'
import React from 'react'
import ReactTooltip from 'react-tooltip'
import './chart-bar.scss'

interface ChartBarProps {
  textInTheBar: boolean
  geneInfo: GeneInfoItem
}

const ChartBar: React.FC<ChartBarProps> = (props) => {
  const { geneInfo } = props

  return (
    <div
      className={`${props.textInTheBar ? 'onlybar' : 'barchart'}`}
      data-tip={`${geneInfo.mutations_rate || 0}% - ${geneInfo.amplifications_rate || 0}%`}
    >
      {!props.textInTheBar ? <div className="itemLabel">{geneInfo.cancer_type}</div> : undefined}

      <div className="barChartItem">
        <div className="itemContent">
          {props.textInTheBar ? (
            <div className="itemLabel">{geneInfo.cancer_type}</div>
          ) : (
            <div className="itemPercentage">
              {geneInfo.mutations_rate || 0}% - {geneInfo.amplifications_rate || 0}%
            </div>
          )}
        </div>
        <div className="itemBarChart">
          <div className="percentage1" style={{ width: `${geneInfo.mutations_rate || 0}%` }}></div>
          <div className="percentage2" style={{ width: `${geneInfo.amplifications_rate || 0}%` }}></div>
        </div>

        <div className="no-printing">
          <ReactTooltip effect="solid" place="left" />
        </div>
      </div>
    </div>
  )
}

export default ChartBar
