import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonSpinner, IonTitle, IonToolbar } from '@ionic/react'
import { ExploreResult } from 'domain/usecases/get-explore-results'
import { ExploreService } from 'infra/services/explore-service'
import { star } from 'ionicons/icons'
import { Viewport } from 'presentation/components'
import React, { useEffect, useState } from 'react'
import './modal-explore-article.scss'

interface ModalExploreArticleProps {
  modal: React.RefObject<HTMLIonModalElement>
  exploreService: ExploreService
  exploreResult: ExploreResult
  openAsIframe: boolean
}

const ModalExploreArticle: React.FC<ModalExploreArticleProps> = (props) => {
  const [isLoadingStarred, setLoadingStarred] = useState(false)
  const [starred, setStarred] = useState(props.exploreResult.starred)
  const [shouldUpdate, setShouldUpdate] = useState(false)

  const closeModalOnNavigateBack = () => {
    window.addEventListener('popstate', () => props.modal.current?.dismiss())
  }

  const resetNavigationState = () => {
    window.history.pushState(null, '', window.location.href)
  }

  const cleanOnNavigateBackListener = () => window.removeEventListener('popstate', () => null)

  useEffect(() => {
    resetNavigationState()

    closeModalOnNavigateBack()

    return cleanOnNavigateBackListener
  }, [])

  const favoriteArticle = () => {
    setLoadingStarred(true)
    return props.exploreService
      .favoriteArticle({
        articleId: props.exploreResult.identifier,
        starred: !starred,
      })
      .then(() => {
        setStarred(!starred)
        setShouldUpdate(true)
      })
      .catch(() => null)
      .finally(() => setLoadingStarred(false))
  }

  return (
    <>
      <IonHeader id="modal-explore-header">
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => favoriteArticle()}>
              {isLoadingStarred ? (
                <IonSpinner name="dots" />
              ) : (
                <IonIcon icon={star} className={starred ? 'starred' : ''} />
              )}
            </IonButton>
          </IonButtons>
          <IonTitle>{props.exploreResult.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => props.modal.current?.dismiss({ shouldUpdate })}>Close</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <section id="modal-explore-article-iframe">
          {props.openAsIframe ? (
            <iframe src={props.exploreResult.link} sandbox="" title="Article Detail" loading="eager" frameBorder="" />
          ) : (
            <Viewport>
              <div className="article-rendering">
                <span className="pmid">PMID: {props.exploreResult.identifier}</span>

                <h2 className="ar-title">{props.exploreResult.title}</h2>

                <div className="abstract-section">
                  {props.exploreResult.abstract ? (
                    props.exploreResult.abstract?.map((item, key) => (
                      <div key={key}>
                        <h3 className="abstract-title">
                          <b>{Object.keys(item)[0]}</b>
                        </h3>
                        <p className="abstract-description">{Object.values(item)[0]}</p>
                      </div>
                    ))
                  ) : (
                    <div>
                      <i>No abstract available</i>
                    </div>
                  )}

                  <a href={props.exploreResult.link} target="_blank" rel="noopener noreferrer">
                    Read full article
                  </a>
                </div>
              </div>
            </Viewport>
          )}
        </section>
      </IonContent>
    </>
  )
}

export default ModalExploreArticle
