import { CognitoUserAttribute, CognitoUserPool } from 'amazon-cognito-identity-js'
import { Account } from 'domain/model/account'
import { SignupAccount } from 'domain/usecases/signup-account'
import { makeRemoteUserPoolCognito } from 'main/factories/http/remote-user-pool-cognito-factory'

export class RemoteSignupAccountCognito implements SignupAccount {
  private userPool: CognitoUserPool = makeRemoteUserPoolCognito()

  signupAccount(params: Account): Promise<unknown> {
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'custom:role',
        Value: params.role,
      }),
      new CognitoUserAttribute({
        Name: 'custom:clinic',
        Value: params.clinic,
      }),
      new CognitoUserAttribute({
        Name: 'custom:name',
        Value: params.name,
      }),
    ]

    return new Promise((resolve, reject) => {
      this.userPool.signUp(params.email, params.password, attributeList, [], (err, res) => {
        if (err) {
          reject(err)
        } else {
          resolve(res)
        }
      })
    })
  }
}
