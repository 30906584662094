import { IonButton, IonFooter, IonSpinner, IonToolbar, useIonToast } from '@ionic/react'
import { ConfirmPatientReportParams } from 'domain/usecases/confirm-patient-report'
import { PatientsService } from 'infra/services/patients-service'
import { ReportsService } from 'infra/services/reports-service'
import { documentText } from 'ionicons/icons'
import { ModalNewPatientValidation } from 'main/validations/usecases/modal-new-patient-validation'
import { Validation } from 'main/validations/usecases/validation'
import { DefaultInput, Message, ModalTitle } from 'presentation/components'
import { useSkipFirstRender } from 'presentation/hooks/skip-first-render'
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { modalNewReportAtom, ModalNewReportState, reportPatientAtom } from '../modal-new-report-atom'

interface ConfirmPatientProps {
  patientsService: PatientsService
  reportsService: ReportsService
  validations: ModalNewPatientValidation
  dismiss: () => void
  onMergeExisting: () => void
  onConfirmNewPatient: () => void
}

const ConfirmNewPatient: React.FC<ConfirmPatientProps> = (props) => {
  const { reportExtract } = useRecoilValue<ModalNewReportState>(modalNewReportAtom)
  const [confirmPatient, setConfirmPatient] = useRecoilState(reportPatientAtom)
  const [loading, setLoading] = useState(false)
  const [presetToast] = useIonToast()
  
  const handleConfirmPatient = () => {
    setLoading(true)
    props.reportsService
      .confirmNewPatientReport({ ...confirmPatient, reportId: reportExtract?.report_id as string })
      .then(() => props.onConfirmNewPatient())
      .catch((e) => {
        showToastError(e.detail[0].msg || 'There was an error trying to confirm patient, try again later.')
      })
      .finally(() => setLoading(false))
  }

  const showToastError = (message: string) => {
    presetToast({
      message,
      duration: 5000,
      color: 'danger',
    })
  }

  const isFirstRender = useSkipFirstRender()

  const validate = (fieldName: keyof ConfirmPatientReportParams) => {
    if (!isFirstRender) {
      const updateState = (stateFieldName: keyof ConfirmPatientReportParams, value: string | null) => {
        setConfirmPatient((current) => {
          return {
            ...current,
            [stateFieldName]: value,
          }
        })
      }

      const validateField = (validations: Validation[], value: string) =>
        props.validations.getValidationError(validations, value)

      switch (fieldName) {
        case 'name':
          updateState('nameError', validateField(props.validations.name, confirmPatient.name))
          break
        case 'MRN':
          updateState('mrnError', validateField(props.validations.mrn, confirmPatient.MRN))
          break
        default:
          break
      }
    }
  }

  useEffect(() => validate('name'), [confirmPatient.name])
  useEffect(() => validate('MRN'), [confirmPatient.MRN])

  return (
    <>
      <section className="ion-padding">
        <ModalTitle label="Confirm New Patient" icon={documentText} />

        <DefaultInput
          name="name"
          state={confirmPatient}
          value={confirmPatient.name}
          setState={setConfirmPatient}
          labelText="Name"
          placeholder="Patient Name"
        />

        {confirmPatient.nameError && <Message color={'error'}>{confirmPatient.nameError}</Message>}

        <DefaultInput
          name="MRN"
          state={confirmPatient}
          value={confirmPatient.MRN}
          setState={setConfirmPatient}
          labelText="Medical Record Number"
          placeholder="MRN"
        />

        {confirmPatient.mrnError && <Message color={'error'}>{confirmPatient.mrnError}</Message>}
      </section>

      <IonFooter>
        <IonToolbar>
          <IonButton slot="end" fill="outline" onClick={props.dismiss}>
            Cancel
          </IonButton>
          <IonButton slot="end" fill="outline" onClick={() => props.onMergeExisting()}>
            Merge to Existing
          </IonButton>
          <IonButton
            slot="end"
            onClick={handleConfirmPatient}
            disabled={
              loading ||
              !!confirmPatient.nameError ||
              !!confirmPatient.mrnError ||
              !confirmPatient.name ||
              !confirmPatient.MRN
            }
          >
            {loading ? <IonSpinner /> : 'Add New Patient'}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  )
}

export default ConfirmNewPatient
