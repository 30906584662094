/* eslint-disable @typescript-eslint/no-explicit-any */
import { IonInput, IonItem, IonLabel } from '@ionic/react'
import { close } from 'ionicons/icons'
import React from 'react'
import ButtonIcon from '../buttons/button-icon'
import './input.scss'

type DefaultInputProps = React.ComponentPropsWithRef<typeof IonInput> & {
  name: string
  labelText?: string
  iconLeft?: JSX.Element
  buttonRight?: JSX.Element
  state: any
  setState: (obj: any) => void
  placeholder: string
  notEditable?: boolean
  notCleanable?: boolean
}

const DefaultInput: React.FC<DefaultInputProps> = (props) => {
  const updateState = (stateKey: string, stateValue: unknown) => {
    props.setState({
      ...props.state,
      [stateKey]: stateValue,
    })
  }

  const buttonRight = () => {
    return (
      props.buttonRight || (
        <ButtonIcon
          fill="clear"
          slot="end"
          className="right-button align-center"
          icon={close}
          onClick={() => updateState(props.name, '')}
        />
      )
    )
  }

  return (
    <IonItem className="default-input" lines="none">
      {props.iconLeft}
      {props.labelText && (
        <IonLabel className="input-label" position="stacked">
          {props.labelText}
        </IonLabel>
      )}
      {props.notEditable ? (
        <div className={`not-editable ${props.state[props.name] ? 'filled' : 'not-filled'}`}>
          {props.state[props.name] || props?.placeholder}
        </div>
      ) : (
        <IonInput {...props} onIonChange={(e) => updateState(e.target.name, e.target.value)} className="textbox" />
      )}

      {props.notEditable ? <></> : props.state[props.name] && buttonRight()}
    </IonItem>
  )
}

export default DefaultInput
