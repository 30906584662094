import { IonModal, useIonViewDidEnter } from '@ionic/react'
import { ConfirmPatientReportParams } from 'domain/usecases/confirm-patient-report'
import { PatientsService } from 'infra/services/patients-service'
import { ReportsService } from 'infra/services/reports-service'
import { makeModalNewPatientValidations } from 'main/factories/builders/modal-new-patient-validation-factory'
import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { modalNewReportAtom, reportPatientAtom } from './modal-new-report-atom'
import './modal-new-report.scss'
import ConfirmNewPatient from './organisms/confirm-new-patient'
import ConfirmPatient from './organisms/confirm-patient'
import NewReport from './organisms/new-report'

type ModalNewReportProps = React.ComponentPropsWithoutRef<typeof IonModal> & {
  modal: React.RefObject<HTMLIonModalElement>
  trigger: string
  reportsService: ReportsService
  patientsService: PatientsService
  notNeededToConfirm: boolean
  onUnconfirmedPatient?: (confirmPatient: ConfirmPatientReportParams) => void
}
export type ModalNewReportSteps = 'new-report' | 'confirm-patient' | 'confirm-new-patient'

export const ModalNewReport: React.FC<ModalNewReportProps> = (props) => {
  const [step, setStep] = useState<ModalNewReportSteps>('new-report')
  const { reportExtract } = useRecoilValue(modalNewReportAtom)
  const confirmPatient = useRecoilValue(reportPatientAtom)

  useIonViewDidEnter(() => {
    setStep('new-report')
  })

  const onWillDismiss = (params: { shouldReload: boolean }) => {
    setStep('new-report')
    props.modal.current?.dismiss(params)
  }

  const handleConfirmPatient = () => {
    props.onUnconfirmedPatient &&
      props.onUnconfirmedPatient({
        ...confirmPatient,
        reportId: reportExtract?.report_id as string,
      })
  }

  return (
    <IonModal ref={props.modal} {...props} backdropDismiss={false} className="modal-new-report-wrapper">
      {
        {
          'new-report': (
            <NewReport
              reportsService={props.reportsService}
              onCancel={() => onWillDismiss({ shouldReload: false })}
              onDidUpload={({ step }) => {
                if (props.notNeededToConfirm) {
                  handleConfirmPatient()
                } else setStep(step)
              }}
              onUploadError={() => null}
            />
          ),
          'confirm-patient': (
            <ConfirmPatient
              patientsService={props.patientsService}
              reportsService={props.reportsService}
              dismiss={() => onWillDismiss({ shouldReload: false })}
              onNewPatient={() => setStep('confirm-new-patient')}
              onConfirmPatient={() => onWillDismiss({ shouldReload: true })}
            />
          ),
          'confirm-new-patient': (
            <ConfirmNewPatient
              patientsService={props.patientsService}
              reportsService={props.reportsService}
              dismiss={() => onWillDismiss({ shouldReload: false })}
              onMergeExisting={() => setStep('confirm-patient')}
              onConfirmNewPatient={() => onWillDismiss({ shouldReload: true })}
              validations={makeModalNewPatientValidations()}
            />
          ),
        }[step]
      }
    </IonModal>
  )
}
