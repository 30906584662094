import { ForgotPassword } from 'domain/usecases/forgot-password'

export class ForgotPasswordService implements ForgotPassword {
  remote: ForgotPassword

  constructor(remote: ForgotPassword) {
    this.remote = remote
  }

  sendResetLink(params: string): Promise<unknown> {
    return this.remote.sendResetLink(params)
  }
}
