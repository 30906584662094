import { IonButton, IonFooter, IonToolbar } from '@ionic/react'
import { PatientsService } from 'infra/services/patients-service'
import { pencil } from 'ionicons/icons'
import { ModalTitle } from 'presentation/components'
import Typeahead, { GroupedOption, TypeaheadItem } from 'presentation/components/typeahead/typeahead'
import { HeadingFilter } from 'presentation/pages/dashboard/organisms/heading/heading-atom'
import React, { useCallback, useEffect, useState } from 'react'
import { ModalFilterExploreState } from './modal-filter-explore-atom'
import { makeCacheLocalStorage } from 'main/factories/cache/cache-local-storage-factory'

interface ModalFilterExploreProps {
  patientsService: PatientsService
  modal: React.RefObject<HTMLIonModalElement>
  filterApplied: HeadingFilter
}

const ModalFilterExplore: React.FC<ModalFilterExploreProps> = (props) => {
  let isMounted = true

  let cachedCancerType: GroupedOption | [] = []

  const [filterState, setFilterState] = useState<ModalFilterExploreState>()
  const clearAll = () => {
    setFilterState({} as ModalFilterExploreState)
  }

  const [cancerTypes, setCancerTypes] = useState<GroupedOption[]>([])
  const [isLoadingCancerTypes, setLoadingCancerTypes] = useState(false)

  const [treatments, setTreatments] = useState<TypeaheadItem[]>([])
  const [isLoadingTreatments, setLoadingTreatments] = useState(false)

  const dates: TypeaheadItem[] = [
    {
      label: 'Less than a year ago',
      value: 'less-than-a-year',
    },
    {
      label: 'Less than 6 months ago',
      value: 'less-than-6-months',
    },
  ]

  const getCancerTypes = () => {
    setLoadingCancerTypes(true)
    props.patientsService
      .getCancerTypes({ from_reports: true })
      .then((result) => {
        if (result) {
          const newItens: GroupedOption[] = result.map((item) => ({ label: item.category, options: item.options }))

          setCancerTypes([(cachedCancerType || []) as GroupedOption, ...newItens])
        } else setCancerTypes([])
      })
      .catch(() => setCancerTypes([]))
      .finally(() => setLoadingCancerTypes(false))
  }

  const getTreatments = () => {
    setLoadingTreatments(true)
    props.patientsService
      .getTreatments()
      .then((result) => {
        if (result) {
          const newItens = result.map((item) => ({ label: item, value: item }))
          setTreatments(newItens)
        } else {
          setTreatments([])
        }
      })
      .catch(() => setTreatments([]))
      .finally(() => setLoadingTreatments(false))
  }

  const handleDebounceFn = useCallback((value: { [key: string]: string }) => {
    const adapter: { key: keyof ModalFilterExploreState; value: string } = {
      key: Object.keys(value)[0] as keyof ModalFilterExploreState,
      value: Object.values(value)[0],
    }

    switch (adapter.key) {
      case 'cancerType':
        getCancerTypes()
        break
      case 'treatment':
        getTreatments()
        break
      default:
        break
    }
  }, [])

  const getLastPatientCancerType = () => {
    const localStorage = makeCacheLocalStorage()

    const cachedValue = localStorage.get('cached-cancer-type')

    if (cachedValue) {
      cachedCancerType = {
        label: 'Last Patient Information',
        options: [{ label: cachedValue as string, value: cachedValue as string }],
      }
    }
  }

  useEffect(() => {
    if (isMounted) setFilterState(props.filterApplied as ModalFilterExploreState)
  }, [props.filterApplied])

  useEffect(() => {
    getLastPatientCancerType()

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      <section className="ion-padding">
        <ModalTitle icon={pencil} label="Search Filters" />

        <Typeahead
          label="Cancer Type"
          placeholder="Select..."
          options={cancerTypes}
          isLoading={isLoadingCancerTypes}
          onTypeaheadDebounce={(cancerType) => handleDebounceFn({ cancerType })}
          onMenuOpen={() => handleDebounceFn({ cancerType: '' })}
          onChange={(cancerType) => setFilterState((s) => ({ ...s, cancerType } as ModalFilterExploreState))}
          value={filterState?.cancerType}
        />

        <Typeahead
          label="Treatment"
          placeholder="Select..."
          options={treatments}
          isLoading={isLoadingTreatments}
          onTypeaheadDebounce={(treatment) => handleDebounceFn({ treatment })}
          onMenuOpen={() => handleDebounceFn({ treatment: '' })}
          onChange={(treatment) => setFilterState((s) => ({ ...s, treatment } as ModalFilterExploreState))}
          value={filterState?.treatment}
        />

        <Typeahead
          label="Date"
          placeholder="Select..."
          options={dates}
          onTypeaheadDebounce={() => null}
          onChange={(dates) => setFilterState((s) => ({ ...s, dates } as ModalFilterExploreState))}
          value={filterState?.dates}
        />
      </section>

      <IonFooter>
        <IonToolbar>
          <IonButton slot="start" fill="clear" onClick={() => clearAll()}>
            Clear All
          </IonButton>
          <IonButton slot="end" fill="outline" onClick={() => props.modal.current?.dismiss()}>
            Cancel
          </IonButton>
          <IonButton slot="end" fill="solid" onClick={() => props.modal.current?.dismiss(filterState)}>
            Apply
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  )
}

export default ModalFilterExplore
