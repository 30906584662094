import { IonButton, IonIcon, IonItem, IonLabel, IonSpinner, IonTextarea, IonToolbar, useIonToast } from '@ionic/react'
import { Report } from 'domain/usecases/get-report'
import { ReportsService } from 'infra/services/reports-service'
import { closeOutline } from 'ionicons/icons'
import React, { useState } from 'react'
import './modal-reply-oncologist.scss'

interface ModalReplyOncologistProps {
  report: Report
  reportsService: ReportsService
  dismiss: (params: { wasSaved: boolean }) => void
}

const ModalReplyOncologist: React.FC<ModalReplyOncologistProps> = (props) => {
  const [message, setMessage] = useState('')
  const [presentToast] = useIonToast()
  const [isSaving, setSaving] = useState(false)

  const save = () => {
    setSaving(true)
    props.reportsService
      .replyToOncologist({
        comment: message,
        reportId: props.report.report_id,
      })
      .then(() => props.dismiss({ wasSaved: true }))
      .catch(() => {
        presentToast({
          message: "We weren't able to add your reply. Please try again later.",
          color: 'danger',
          duration: 5000,
        })
      })
      .finally(() => setSaving(false))
  }

  return (
    <>
      <section className="ion-padding">
        <h2>Reply to {props.report.doctor_name}</h2>

        <IonItem className="sharing-message">
          <IonLabel position="stacked" color={'medium'}>
            Your Comment
          </IonLabel>
          <IonTextarea
            rows={2}
            maxlength={560}
            value={message}
            onIonInput={(e) => setMessage(e.target.value as string)}
            placeholder="Here is my feedback about this Report..."
          />
          <IonButton fill="clear" slot="end" color={'medium'} onClick={() => setMessage('')}>
            <IonIcon icon={closeOutline}></IonIcon>
          </IonButton>
        </IonItem>
      </section>

      <IonToolbar className="reply-onco-toolbar">
        <IonButton slot="end" fill="outline" onClick={() => props.dismiss({ wasSaved: false })}>
          Cancel
        </IonButton>

        <IonButton disabled={!message.length || isSaving} slot="end" onClick={save}>
          {isSaving ? <IonSpinner /> : 'Save'}
        </IonButton>
      </IonToolbar>
    </>
  )
}

export default ModalReplyOncologist
