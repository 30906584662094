import './mutation-amplification.scss'
import React from 'react'

const MutationAmplification: React.FC = () => {
  return (
    <>
      <div className="chart-info-item">
        <span className="chart-info-ball mutation"></span>
        <span>Mutation</span>
      </div>
      <div className="chart-info-item">
        <span className="chart-info-ball amplification"></span>
        <span>Amplification</span>
      </div>
    </>
  )
}

export default MutationAmplification
