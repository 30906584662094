import { IonCheckbox } from '@ionic/react'
import { Treatment } from 'domain/usecases/get-report'
import { searchSharp, trash } from 'ionicons/icons'
import ActionItem from 'presentation/components/action-item/action-item'
import React, { useState } from 'react'
import { RelevanceItem } from '../relevance-item/relevance-item'
import './therapies-trials-item.scss'

interface TherapiesTrialsItemProps {
  item: Treatment
  selectable?: boolean
  hideActions: boolean
  onSelect: (treatment: Treatment) => void
  onSearchClick: (treatment: Treatment) => void
}

const TherapiesTrialsItem: React.FC<TherapiesTrialsItemProps> = (props) => {
  const [treatment, setTreatment] = useState<Treatment>(JSON.parse(JSON.stringify(props.item)))

  const getReasonLabel = () => {
    switch (treatment.reason) {
      case 'clinical_trial':
        return 'Clinical Trial'
      case 'FDA-approved therapy, current diagnosis':
        return 'FDA Approved'
      case 'FDA-approved therapy, other indicators':
        return 'Off Label'
      default:
        return treatment.reason
    }
  }

  return (
    <tr className="ttt-row">
      <td className={`ttt-therapy ${props.selectable ? 'selectable' : ''}`}>
        {props.selectable && (
          <IonCheckbox
            className="ttt-checkbox"
            checked={treatment.selected}
            onClick={() => {
              props?.onSelect({ ...treatment, selected: !treatment.selected })
              setTreatment((t) => ({ ...t, selected: !t.selected }))
            }}
          />
        )}
        <div>
          {treatment.detail.link ? (
            <a href={treatment.detail.link} className="link-title-table" target="_blank" rel="noopener noreferrer">
              <b>{treatment.name}</b>
            </a>
          ) : (
            <b>{treatment.name}</b>
          )}

          <br />

          <small className="tttt-relevant">
            {treatment.genes && treatment.genes.length ? (
              <>
                Relevant for: <b>{treatment.genes.join(', ')}</b>
              </>
            ) : (
              'Relevant because of Biomarker Findings'
            )}
          </small>
        </div>
      </td>
      <td>
        {treatment.detail.prescription ? (
          <a href={treatment.detail.prescription.url} target="_blank" rel="noopener noreferrer">
            <img src={treatment.detail.prescription.image_url} alt={treatment.detail.prescription.drug} height={40} />
          </a>
        ) : undefined}
        {treatment.detail.description ? <div>{treatment.detail.description}</div> : <></>}
        {treatment.detail.locations ? <div>{treatment.detail.locations.join(', ')}</div> : <></>}
        {treatment.detail.phase ? <div>{treatment.detail.phase}</div> : <></>}
        {treatment.detail.status ? (
          <div>
            Status: <b>{treatment.detail.status}</b>
          </div>
        ) : (
          <></>
        )}
      </td>
      <td>
        <RelevanceItem relevance={treatment.relevance || '-'} />
      </td>
      <td>{getReasonLabel()}</td>
      {!props.hideActions ? (
        <td className="ttt-actions no-printing">
          {/* <ActionItem data-tip="Rx" src="assets/logo.svg" icon="" /> */}
          {/* <ActionItem tooltip="Report" icon={documentTextOutline} /> */}
          <ActionItem
            tooltip="Delete"
            icon={trash}
            onClick={() => console.log('delete')}
          />
          <ActionItem
            tooltip="Search"
            icon={searchSharp}
            onClick={() => {
              const openTreatmentAsNewTab = () =>
                window.open(`https://www.clinicaltrials.gov/ct2/show/${treatment.name}`, '_newtab')

              if (treatment.type === 'clinical_trial') openTreatmentAsNewTab()
              else props.onSearchClick(treatment)
            }}
          />
        </td>
      ) : (
        <></>
      )}
    </tr>
  )
}

export default TherapiesTrialsItem
