import { GetValidationError } from 'main/validations/get-validation-error'
import { RequiredValidation } from 'main/validations/required-validation'
import { StringSizeValidation } from 'main/validations/string-size-validation'
import { ModalNewPatientValidation } from 'main/validations/usecases/modal-new-patient-validation'

export const makeModalNewPatientValidations = (): ModalNewPatientValidation => {
  const makeRequiredValidation = new RequiredValidation()
  const [minName, maxName] = [10, 60]
  const [minMRN, maxMRN] = [6, 20]

  const messageName = `Name needs to have between ${minName} and ${maxName} characters`
  const messageMrn = `MRN needs to have between ${minMRN} and ${maxMRN} characters`

  return {
    name: [makeRequiredValidation, new StringSizeValidation(minName, maxName, 'name', messageName)],
    mrn: [makeRequiredValidation, new StringSizeValidation(minMRN, maxMRN, 'mrn', messageMrn)],
    getValidationError: new GetValidationError().getValidationError,
  }
}
