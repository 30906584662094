import { makeLoginAccountService } from 'main/factories/services/login-account-service-factory'
import { LoginPage } from 'presentation/pages'
import React from 'react'
import { makeLoginFormValidations } from '../builders/login-form-validation-factory'
import { makeUserService } from '../services/user-service-factory'

const makeLoginPage: React.FC = () => {
  return (
    <LoginPage
      auth={makeLoginAccountService()}
      validations={makeLoginFormValidations()}
      userInfoService={makeUserService()}
    />
  )
}

export default makeLoginPage
