import { IonButton, IonIcon, IonModal, IonSpinner } from '@ionic/react'
import { Treatment } from 'domain/usecases/get-report'
import { ReportsService } from 'infra/services/reports-service'
import { route } from 'main/routes'
import { ButtonRound, NotFound, Title } from 'presentation/components'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { useRecoilState, useRecoilValue } from 'recoil'
import { reportAtom } from '../../report-atom'
import ModalSelectTreament from '../modal-select-treatment/modal-select-treatment'
import {
  modalSelectTreatmentAtom,
  ModalSelectTreatmentState,
} from '../modal-select-treatment/modal-select-treatment-atom'
import TherapiesTrialsTable from './therapies-trials-table/therapies-trials-table'
import './therapies-trials.scss'
import { add } from 'ionicons/icons'
import ModalCreateTreatment from '../modal-create-treatment/modal-create-treatment'

interface TherapiesTrialsProps {
  reportsService: ReportsService
  isViewOnly: boolean
  onTreatmentSaved: () => void
}

const TherapiesTrials: React.FC<TherapiesTrialsProps> = (props) => {
  const { report, isLoadingReport } = useRecoilValue(reportAtom)
  const modalSelect = useRef<HTMLIonModalElement>(null)
  const [isSelectOpen, setSelectOpen] = useState(false)
  const [treatmentsSelection, setTreatmentsSelection] = useRecoilState(modalSelectTreatmentAtom)
  const hasTreatments = report?.treatments && report?.treatments.length
  const hasSelectedTreatments = report?.selected_treatments && report?.selected_treatments.treatments.length
  const [treatmentsModal, setTreatments] = useState<Treatment[]>([])
  const navigate = useHistory()
  const [createTreatmentOpen, setCreateTreatmentOpen] = useState(false)
  const saveTreatment = () => {
    setTreatmentsSelection((s) => ({ ...s, isSaving: true }))
    props.reportsService
      .selectTreatment({
        note: treatmentsSelection.notes,
        treatments: treatmentsSelection.treatments,
        reportId: report?.report_id as string,
      })
      .then(() => modalSelect.current?.dismiss({ wasSaved: true }))
      .catch(() => modalSelect.current?.dismiss())
      .finally(() => setTreatmentsSelection((s) => ({ ...s, isSaving: false })))
  }

  const makeTreatments = () => {
    let treatmentsAux: Treatment[] = []

    if (hasSelectedTreatments) {
      const selectedAux = report?.selected_treatments?.treatments.map((t) => ({ ...t, selected: true }))
      treatmentsAux = treatmentsAux.concat(selectedAux)
      setTreatmentsSelection((s) => ({ ...s, treatments: selectedAux as Treatment[] }))
    }

    treatmentsAux = treatmentsAux.concat(report?.treatments as Treatment[])

    setTreatments(treatmentsAux)
  }

  const shortcutLink = (treatment: Treatment) => {
    navigate.push(route.explore.url, {
      cancerType: report?.patient.cancer_type || report?.diagnosis,
      geneName: treatment.name,
    })
  }

  useEffect(() => {
    makeTreatments()
    setTreatmentsSelection((s) => ({ ...s, notes: report?.selected_treatments?.note } as ModalSelectTreatmentState))
  }, [report])

  return (
    <>
      <section className="therapies-trials">
        {!isLoadingReport ? (
          <>
            <div className="tt-title">
              <Title size="h2">Therapies & Trials</Title>
              {props.isViewOnly ? undefined : (
                <ButtonRound
                  size="small"
                  onClick={() => setSelectOpen(true)}
                  disabled={!(hasTreatments || hasSelectedTreatments)}
                >
                  Recommend Treatment
                </ButtonRound>
              )}
            </div>

            {hasSelectedTreatments ? (
              <div className="tt-unselected-itens">
                <Title size="h3" className="treatments-title">
                  Selected Treatments
                </Title>
                <TherapiesTrialsTable
                  itens={report?.selected_treatments.treatments as Treatment[]}
                  onSelect={() => null}
                  note={report.selected_treatments.note}
                  hideActions={false}
                  onSearchClick={(treatment) => shortcutLink(treatment)}
                />
              </div>
            ) : (
              <></>
            )}

            {hasTreatments ? (
              <div className="tt-unselected-itens">
                {hasSelectedTreatments ? (
                  <Title size="h3" className="treatments-title">
                    Other Treatments
                  </Title>
                ) : (
                  <></>
                )}
                <TherapiesTrialsTable
                  itens={report?.treatments as Treatment[]}
                  onSelect={() => null}
                  hideActions={false}
                  onSearchClick={(treatment) => shortcutLink(treatment)}
                />
              </div>
            ) : hasSelectedTreatments ? (
              <></>
            ) : (
              <NotFound>No treatments available for the patient</NotFound>
            )}
            <div className="tt-actions">
              <IonButton
                size="small"
                fill="outline"
                onClick={() => setCreateTreatmentOpen(true)}
              >
                <IonIcon icon={add} />
                Add Treatment
              </IonButton>
            </div>
          </>
        ) : (
          <div className="tt-spinner">
            <IonSpinner name="dots" />
          </div>
        )}
        {
          report && report.genomic_variants && report.genomic_variants.length > 0 && (
            <ModalCreateTreatment
              reportId={report.report_id}
              genesList={report.genomic_variants.map((variant) => variant.name) || []}
              isOpen={createTreatmentOpen}
              setIsOpen={setCreateTreatmentOpen}
              reportsService={props.reportsService}
              onDidDismiss={() => {
                props.onTreatmentSaved()
              }}
            />
          )
        }
      </section>

      <IonModal
        ref={modalSelect}
        isOpen={isSelectOpen}
        onDidDismiss={(data) => {
          setSelectOpen(false)
          if (data.detail.data && data.detail.data.wasSaved) {
            props.onTreatmentSaved && props.onTreatmentSaved()
          }
        }}
        className="therapies-select"
      >
        <ModalSelectTreament onDismiss={() => modalSelect.current?.dismiss()} onSave={() => saveTreatment()}>
          <TherapiesTrialsTable
            itens={treatmentsModal as Treatment[]}
            selectable={true}
            onSelect={(treatmentModal) => {
              const currentSelection: Treatment[] = JSON.parse(JSON.stringify(treatmentsSelection.treatments))

              if (treatmentModal.selected) {
                currentSelection?.push(treatmentModal)

                setTreatmentsSelection((s) => ({ ...s, treatments: currentSelection as Treatment[] }))
              } else {
                const newSelection = currentSelection?.filter((t) => t.id !== treatmentModal.id)

                setTreatmentsSelection((s) => ({ ...s, treatments: newSelection as Treatment[] }))
              }
            }}
            hideActions={true}
            onSearchClick={() => null}
          />
        </ModalSelectTreament>
      </IonModal>
    </>
  )
}

export default TherapiesTrials
