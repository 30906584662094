import { ResetPassword } from 'domain/usecases/reset-password'
import { RemoteGetUserCognito } from './remote-get-user-cognito'

export class RemoteResetPasswordCognito implements ResetPassword {
  private cognitoUser = new RemoteGetUserCognito()

  verifyCode(username: string, code: string, password: string) {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.cognitoUser.getCognitoUser(username)

      if (!cognitoUser) {
        reject(`Could not find ${username}`)
        return
      }

      cognitoUser.confirmPassword(code, password, {
        onSuccess: () => resolve('Password updated'),
        onFailure: (err) => reject(err),
      })
    })
  }
}
