import { TypeaheadItem } from 'presentation/components/typeahead/typeahead'

export const stringToTypeaheadItem = (result: string[]): TypeaheadItem[] => {
  if (Array.isArray(result)) {
    const newResult: TypeaheadItem[] = result.map((option) => ({ label: option, value: option }))

    return newResult
  }

  return []
}
