import { IonIcon, IonSpinner } from '@ionic/react'
import { Patient, PatientLastReports } from 'domain/usecases/get-patient'
import { labs } from 'helpers/labs'
import { chevronForwardOutline } from 'ionicons/icons'
import { route } from 'main/routes'
import moment from 'moment'
import MarkerRead from 'presentation/components/marker-read/marker-read'
import React from 'react'
import { useHistory } from 'react-router'
import './history.scss'

interface ReportHistoryProps {
  lastReports: PatientLastReports[]
  isLoading: boolean
  patient: Patient
}

const ReportHistory: React.FC<ReportHistoryProps> = (props) => {
  const navigate = useHistory()

  return (
    <section className="patient-report-history">
      <table>
        <thead>
          <tr>
            <td colSpan={4}>
              <h4>Reports History</h4>
            </td>
          </tr>
          <tr>
            <th>
              <b>REPORTS</b>
            </th>
            <th>
              <b>DATE</b>
            </th>
            <th>
              <b>LAB</b>
            </th>
            <th>
              <b>ACTIONS</b>
            </th>
          </tr>
        </thead>

        <tbody>
          {props.isLoading ? (
            <tr className="prh-spinner">
              <td colSpan={4} className="spinner">
                <IonSpinner name="dots" />
              </td>
            </tr>
          ) : undefined}
          {props.lastReports &&
            props.lastReports.map((lastRepot, index) => (
              <tr key={index} className={`${lastRepot.read ? 'read' : 'not-read'}`}>
                <td>
                  <MarkerRead read={lastRepot.read} />
                  <b>Report #{props.lastReports.length - index}</b>
                </td>
                <td>
                  <b>{lastRepot.report_date ? moment(lastRepot.report_date).format('MMM DD, YYYY') : '-'}</b>
                </td>
                <td>{labs[lastRepot.lab]}</td>
                <td
                  style={{ cursor: 'pointer', fontSize: 20 }}
                  onClick={() => {
                    navigate.push(`${route.report.url}/${lastRepot.report_id}`, props.patient)
                  }}
                >
                  <IonIcon icon={chevronForwardOutline} />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </section>
  )
}

export default ReportHistory
