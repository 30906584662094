import { AuthenticationDetails } from 'amazon-cognito-identity-js'
import { LoginAccount } from 'domain/usecases/login-account'
import { RemoteGetUserCognito } from './remote-get-user-cognito'

export class RemoteLoginAccountCognito implements LoginAccount {
  private cognitoUser = new RemoteGetUserCognito()

  loginAccount(params: LoginAccount.Params): Promise<LoginAccount.Model> {
    return new Promise<LoginAccount.Model>((resolve, reject) => {
      const authenticationData = {
        Username: params.email,
        Password: params.password,
      }

      const authenticationDetails = new AuthenticationDetails(authenticationData)

      const currentUser = this.cognitoUser.getCognitoUser(params.email)

      currentUser.authenticateUser(authenticationDetails, {
        onSuccess(res) {
          resolve({
            token: res.getAccessToken().getJwtToken(),
          })
        },
        onFailure(err) {
          reject(err)
        },
      })
    }).catch((err) => {
      throw err
    })
  }
}
