import { IonContent, IonPage } from '@ionic/react'
import React from 'react'
import Footer from '../footer/footer'
import Viewport from '../viewport/viewport'
import './background.scss'

const Background: React.FC = (props) => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="box-wrapper">
          <div className="box-top">
            <h1 className="logo">OncoRx Insights</h1>
          </div>

          <Viewport>
            <div className="box-bottom">{props.children}</div>
          </Viewport>
        </div>
      </IonContent>
      <Footer />
    </IonPage>
  )
}

export default Background
