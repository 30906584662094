import axios from 'axios'
import { AccessTokenCache } from 'infra/cache/set-access-token-cache'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { GetUserInfo } from '../services/usecases/get-user-info'
import { HttpClient } from './usecases/http-client'

export default class AxiosHttpClient implements HttpClient {
  private toastErrorId = 'toast-error-id'

  constructor(private readonly accessToken: AccessTokenCache, private session: GetUserInfo) {}

  request(params: HttpClient.Params): Promise<unknown> {
    const token = this.accessToken.get()

    return new Promise((resolve, reject) => {
      axios
        .request({
          method: params.type,
          data: params.body,
          url: params.url,
          headers: {
            ...params.headers,
            ...(token ? { Authorization: `Bearer ${this.accessToken.get()}` } : {}),
          },
        })
        .then((response) => resolve(response.data))
        .catch(({ response }) => {
          if (response.status === 401)
            this.refreshToken().then(() => {
              this.request(params)
                .then((result) => resolve(result))
                .catch(() => reject(response.data))
            })
          else {
            if (Number(response.status) >= 500) {
              toast(
                `
              500. Server Error. An error has occurred, and we are working to fix the problem. 
              If you need immediate help from our team, please contact us, support@oncorxinsights.com
            `,
                {
                  position: 'bottom-center',
                  style: {
                    background: '#eb445a',
                    color: 'white',
                  },
                  hideProgressBar: true,
                  draggable: false,
                  closeButton: true,
                  toastId: this.toastErrorId,
                  closeOnClick: false,
                  autoClose: false,
                }
              )
            } else reject(response.data)
          }
        })
    })
  }

  private refreshToken(): Promise<void> {
    return new Promise((resolve) => {
      this.session.getUserInfo().then(() => resolve())
    })
  }
}
