import DashboardPage from 'presentation/pages/dashboard/dashboard'
import React from 'react'
import { makePatientsService } from '../services/patients-service-factory'
import { makeReportsService } from '../services/reports-service-factory'
import { makeUserService } from '../services/user-service-factory'

const makeDashboardPage: React.FC = () => {
  return (
    <DashboardPage
      userService={makeUserService()}
      patientsService={makePatientsService()}
      reportsService={makeReportsService()}
    />
  )
}

export default makeDashboardPage
