import { LoginAccountService } from 'infra/services/login-account-service'
import { UserService } from 'infra/services/user-service'
import { route } from 'main/routes'
import { LoginFormValidation } from 'main/validations/usecases/login-form-validation'
import { Background } from 'presentation/components'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useRecoilState, useResetRecoilState } from 'recoil'
import './login.scss'
import { LoginForm } from './organisms/login-form'
import { loginFormState } from './organisms/login-form/login-form-atom'

export type LoginPageProps = {
  auth: LoginAccountService
  validations: LoginFormValidation
  userInfoService: UserService
}

const LoginPage: React.FC<LoginPageProps> = (props: LoginPageProps) => {
  const [loginForm, setLoginFormGroup] = useRecoilState(loginFormState)
  const navigate = useHistory()
  const resetState = useResetRecoilState(loginFormState)

  const getRedirection = () => {
    const query = new URLSearchParams(window.location.search)

    return query.get('redirect-report')
  }

  const toggleLoading = (isLoading: boolean) => {
    setLoginFormGroup((current) => ({ ...current, isLoading }))
  }

  useEffect(() => {
    return () => resetState()
  }, [])

  const login = (): void => {
    toggleLoading(true)
    props.auth
      .loginAccount({
        email: loginForm.email,
        password: loginForm.password,
      })
      .then(() => {
        const redirectionRoute = getRedirection()

        if (redirectionRoute && redirectionRoute.length) navigate.replace(`${route.report.url}/${redirectionRoute}`)
        else navigate.replace(route.dashboard.url)
      })
      .catch(() => {
        setLoginFormGroup((current) => ({
          ...current,
          remoteError: 'Wrong credentials. Try again or recover your password',
        }))
      })
      .finally(() => toggleLoading(false))
  }

  return (
    <Background>
      <LoginForm onSubmit={login} className="login-form-card" validations={props.validations}></LoginForm>
    </Background>
  )
}

export default LoginPage
