import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_DEFAULT_REGION as string,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY as string,
  },
})

const useS3Bucket = () => {
  const uploadReportPDF = async (key: string, file: File) => {
    try {
      const arrayBuffer = await file.arrayBuffer()

      const uploadParams = {
        Bucket: process.env.REACT_APP_AWS_S3_PDF_BUCKET_NAME as string,
        Key: key,
        Body: new Uint8Array(arrayBuffer),
        ContentType: 'application/pdf',
      }
      const command = new PutObjectCommand(uploadParams)
      const response = await s3Client.send(command)
      return response
    } catch (error) {
      console.error('Error uploading PDF:', error)
      throw error
    }
  }

  return { uploadReportPDF }
}

export default useS3Bucket
