import { IonIcon } from '@ionic/react'
import { chatboxEllipsesOutline } from 'ionicons/icons'
import moment from 'moment'
import React from 'react'
import './comments-item.scss'

export interface CommentsProps {
  note: string
  created: string
  fromText: string
}

const CommentsItem: React.FC<CommentsProps> = (props) => {
  return (
    <div className="progress-note">
      <div className="icon">
        <IonIcon icon={chatboxEllipsesOutline} />
      </div>
      <div className="text-box">
        <small>
          <b>{moment(props.created).format('MMM DD YYYY')}</b> {props.fromText ? `from ${props.fromText}` : undefined}
        </small>
        <div>{props.note}</div>
      </div>
    </div>
  )
}

export default CommentsItem
