import { ResetPasswordPage } from 'presentation/pages'
import React from 'react'
import { makeSignupFormValidations } from '../builders/signup-form-validation-factory'
import { makeResetPasswordService } from '../services/reset-password-service-factory'

const makeResetPasswordPage: React.FC = () => {
  return <ResetPasswordPage validations={makeSignupFormValidations()} resetPassword={makeResetPasswordService()} />
}

export default makeResetPasswordPage
