import { rolesUrl } from 'infra/config/aws-env'
import { HttpClient } from 'infra/http/usecases/http-client'
import { GenericSelector } from './usecases/generic-selector'
import { GetRoles } from './usecases/get-roles'

export class RolesService implements GetRoles {
  constructor(private baseUrl: string, private httpClient: HttpClient) {}

  getRoles(): Promise<GenericSelector[]> {
    return this.httpClient
      .request({
        headers: {
          'Content-Type': 'application/json',
        },
        type: 'get',
        url: `${this.baseUrl}${rolesUrl.getRoles}`,
      })
      .then((clinicsList) => clinicsList)
      .catch((error) => error)
  }
}
