import { Cache } from './usecases/cache'
import { GetCache } from './usecases/get-cache'
import { SetCache } from './usecases/set-cache'

export class AccessTokenCache implements SetCache, GetCache {
  private cache: Cache

  constructor(cache: Cache) {
    this.cache = cache
  }

  set(value: string) {
    this.cache.set('access-token', value)
  }

  get(): string {
    return JSON.parse(JSON.stringify(this.cache.get('access-token')))
  }
}
