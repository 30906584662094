import { Treatment } from 'domain/usecases/get-report'
import { ButtonRound } from 'presentation/components'
import React, { useEffect, useState } from 'react'
import TherapiesTrialsItem from '../therapies-trials-item/therapies-trials-item'
import './therapies-trials-table.scss'

interface TherapiesTrialsTableProps {
  itens: Treatment[]
  selectable?: boolean
  note?: string
  hideActions: boolean
  onSelect: (treatment: Treatment) => void
  onSearchClick: (treatment: Treatment) => void
}

const TherapiesTrialsTable: React.FC<TherapiesTrialsTableProps> = (props) => {
  const qtyShow = 8
  const [treatments, setTreatments] = useState<Treatment[]>([])
  const [showMore, setShowMore] = useState<null | boolean>(null)

  useEffect(() => {
    if (props.itens) setTreatments(props.itens.slice(0, qtyShow))
  }, [props.itens])

  return (
    <div className="therapies-trials-table">
      <table>
        <thead>
          <tr className="ttt-header">
            <th>Therapy</th>
            <th>Details</th>
            <th>Relevance</th>
            <th>Reasons</th>
            {!props.hideActions ? <th>Actions</th> : <></>}
          </tr>
        </thead>

        <tbody>
          {treatments.map((item, index) => (
            <TherapiesTrialsItem
              key={index}
              item={item}
              selectable={props.selectable}
              onSelect={(treatment) => props.onSelect(treatment)}
              hideActions={props.hideActions}
              onSearchClick={() => props.onSearchClick(item)}
            />
          ))}
          {props.note ? (
            <tr>
              <td colSpan={5} className="ttt-notes">
                <b>Note from Oncologist:</b> {props.note}
              </td>
            </tr>
          ) : null}
        </tbody>
      </table>

      {props.itens && props.itens.length > qtyShow && showMore !== false && (
        <div className="ttt-show-more">
          <ButtonRound
            onClick={() => {
              setTreatments(props.itens)
              setShowMore(false)
            }}
            size="small"
          >
            + More Treatments
          </ButtonRound>
        </div>
      )}
    </div>
  )
}

export default TherapiesTrialsTable
