import SignupPage from 'presentation/pages/signup/signup'
import React from 'react'
import { makeSignupFormValidations } from '../builders/signup-form-validation-factory'
import { makeClinicsService } from '../services/clinics-service-factory'
import { makeLoginAccountService } from '../services/login-account-service-factory'
import { makeRolesService } from '../services/roles-service-factory'
import { makeSignupAccount } from '../services/signup-account-service-factory'

const makeSignupPage: React.FC = () => {
  return (
    <SignupPage
      authSignup={makeSignupAccount()}
      authLogin={makeLoginAccountService()}
      validations={makeSignupFormValidations()}
      clinicsService={makeClinicsService()}
      rolesService={makeRolesService()}
    />
  )
}

export default makeSignupPage
