import {
  IonButton,
  IonFooter,
  IonIcon,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonToolbar,
  ToastButton,
  useIonToast,
} from '@ionic/react'
import { ReportExtract, ValidationErrorResponse } from 'domain/usecases/add-new-report'
import { ReportsService } from 'infra/services/reports-service'
import { cloudUploadSharp, documentText } from 'ionicons/icons'
import { DefaultInput, ModalTitle } from 'presentation/components'
import React, { useRef, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { ModalNewReportSteps } from '../modal-new-report'
import { modalNewReportAtom, reportPatientAtom } from '../modal-new-report-atom'
import useS3Bucket from 'presentation/hooks/useS3Bucket'

interface NewReportProps {
  reportsService: ReportsService
  onCancel: () => void
  onDidUpload: (params: { info: ReportExtract; step: ModalNewReportSteps }) => void
  onUploadError: (info: ValidationErrorResponse) => void
}

type NewReportFile = { file: File; fileName: string; lab: string }

const NewReport: React.FC<NewReportProps> = (props) => {
  const labOptions = [
    { label: 'Foundation', value: 'foundation_one' },
    { label: 'Tempus', value: 'tempus' },
    { label: 'Foundation Liquid', value: 'foundation_liquid' },
    { label: 'Guardant', value: 'guardant' }
  ]
  const inputRef = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState<NewReportFile>({} as NewReportFile)
  const [isUploading, setIsUploading] = useState(false)
  const [presentToast] = useIonToast()
  const setReportExtract = useSetRecoilState(modalNewReportAtom)
  const setConfirmPatient = useSetRecoilState(reportPatientAtom)
  const { uploadReportPDF } = useS3Bucket()

  const getFile: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const maxFileSizeMb = 20
    const file = e?.target?.files ? e?.target?.files[0] : null
    const toMb = (n: number) => n / 1024 / 1024

    if (!file) return showToastError('Invalid file, please try again!')
    if (toMb(file.size) > maxFileSizeMb) return showToastError('We only accept PDF files of up to 20Mb')

    setSelectedFile((s) => ({ ...s, file, fileName: file.name }))
  }

  const handleUpload = () => {
    setIsUploading(true)
    props.reportsService
      .addNewReport({ lab: selectedFile.lab })
      .then((report) => {

        const reportExtract = report as ReportExtract

        uploadReportPDF(reportExtract.presigned_url.upload_key, selectedFile.file).then(() => {
          const patientExists = reportExtract.patient && reportExtract.patient.exists
          setReportExtract({ reportExtract: reportExtract })

          if (patientExists) {
            setConfirmPatient({
              MRN: reportExtract.patient.MRN,
              name: reportExtract.patient.name,
              reportId: reportExtract.report_id,
            })
          }

          setIsUploading(false)

          props.onDidUpload({
            info: { ...reportExtract },
            step: patientExists ? 'confirm-patient' : 'confirm-new-patient',
          })
        })
      })
      .catch((error) => {
        setIsUploading(false)
        props.onUploadError(error as ValidationErrorResponse)
        showToastError(
          `An error occurred while trying to upload this report. 
        Please try it again later or contact the support team: support@oncorxinsights.com `,
          [
            {
              text: 'Contact',
              handler: () => {
                window.location.href = 'mailto:support@oncorxinsights.com'
              },
            },
          ]
        )
      })
  }

  const showToastError = (message: string, buttons?: ToastButton[]) => {
    presentToast({
      message: message,
      duration: 5000,
      color: 'danger',
      buttons,
    })
  }

  return (
    <>
      <section className="ion-padding">
        <ModalTitle label="New Report" icon={documentText} />

        <IonItem className="lab-selection">
          <IonLabel position="stacked">Lab</IonLabel>
          <IonSelect
            placeholder={'Select the lab'}
            onIonChange={(e) => setSelectedFile((s) => ({ ...s, lab: e.target.value }))}
          >
            {labOptions.map((option, index) => (
              <IonSelectOption key={index} value={option.value}>
                {option.label}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>

        <DefaultInput
          name="fileName"
          state={selectedFile}
          value={selectedFile.fileName}
          setState={setSelectedFile}
          labelText="File"
          placeholder="Max size for PDF: 20mb"
          notEditable
        />

        <input
          id="pdf-input"
          ref={inputRef}
          type="file"
          className="ion-input"
          accept="application/pdf"
          onChange={getFile}
        />

        <IonButton className="upload-button" fill="outline" expand="block" onClick={() => inputRef?.current?.click()}>
          <IonIcon icon={cloudUploadSharp} slot="start" />
          Upload File
        </IonButton>
      </section>

      <IonFooter>
        <IonToolbar>
          <IonButton slot="end" fill="outline" onClick={() => props.onCancel()}>
            Cancel
          </IonButton>
          <IonButton
            slot="end"
            onClick={handleUpload}
            disabled={!selectedFile.lab || !selectedFile.fileName || isUploading}
          >
            {isUploading ? <IonSpinner /> : <>Next</>}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </>
  )
}

export default NewReport
