import { IonIcon } from '@ionic/react'
import React from 'react'
import Title from '../title/title'
import './modal-title.scss'

interface ModalTitleProps {
  icon: string
  label: string
}

const ModalTitle: React.FC<ModalTitleProps> = (props) => {
  return (
    <div className="title">
      <IonIcon icon={props.icon} />
      <br />
      <Title size="h2">{props.label}</Title>
    </div>
  )
}

export default ModalTitle
