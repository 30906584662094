import React from 'react'

type FlexGridProps = React.CSSProperties & {
  gap?: number
  align?: 'center' | 'left' | 'right'
  className?: string
}

const FlexGrid: React.FC<FlexGridProps> = (props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: props.gap,
        justifyContent: props.align || 'initial',
        ...props,
      }}
    >
      {props.children}
    </div>
  )
}

export default FlexGrid
