import React, { useRef, useState } from 'react'
import {
  IonButton,
  IonContent,
  IonFooter,
  IonInput,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonToolbar,
  useIonToast
} from '@ionic/react'
import { ModalTitle } from 'presentation/components'
import { add } from 'ionicons/icons'
import { AddTreatmentParams } from 'domain/usecases/add-treatment'
import { ReportsService } from 'infra/services/reports-service'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

type ModalCreateTreatmentProps = React.ComponentPropsWithoutRef<typeof IonModal> & {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  reportsService: ReportsService
  genesList: string[]
  reportId: string
  onDidDismiss: () => void
}

const cleanForm = {
  name: '',
  reason: '',
  type: 'therapy',
  relevance: '',
  genes: [],
  detail: {
    description: [],
    phase: '',
    locations: [],
    status: '',
    prescription: {
      drug: '',
      image_url: '',
      url: ''
    },
    link: ''
  }
}

const formSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  description: z.string().min(1, { message: 'Description is required' }),
  relevance: z.string().min(1, { message: 'Relevance is required' }),
  type: z.string().min(1, { message: 'Type is required' }),
  reason: z.string().min(1, { message: 'Reason is required' }),
  genes: z.array(z.string(), { message: 'Genes are required' }).min(1, { message: 'Genes are required' }),
})

const ModalCreateTreatment: React.FC<ModalCreateTreatmentProps> = (props) => {
  const [description, setDescription] = useState('')
  const [editState, setEditState] = useState<AddTreatmentParams>({
    report_id: props.reportId,
    therapy: { ...cleanForm }
  })
  const [isSaving, setSaving] = useState(false)
  const [presentToast] = useIonToast()
  const modal = useRef<HTMLIonModalElement>(null)

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: zodResolver(formSchema)
  })

  const save = () => {
    setSaving(true)
    const formData = { ...editState }
    formData.therapy.detail.description = [description]
    props.reportsService.addTreatment(formData)
      .then(() => modal.current?.dismiss({ wasEdited: true }))
      .catch((message) => {
        presentToast({
          message,
          duration: 5000,
          color: 'danger',
        })
      })
      .finally(() => setSaving(false))
  }

  return (
    <IonModal
      ref={modal}
      className="modal-create-treatment"
      isOpen={props.isOpen}
      onDidDismiss={(info) => {
        setEditState({
          report_id: props.reportId,
          therapy: { ...cleanForm }
        })
        setDescription('')
        reset()
        if (info.detail.data && info.detail.data.wasEdited) {
          props.onDidDismiss()
          props.setIsOpen(false)
        }
      }}
    >
      <IonContent className="ion-padding modal-edit-report-header">
        <ModalTitle icon={add} label="Add Treatment" />
        <form id="create-treatment-form" onSubmit={handleSubmit(save)}>
          <div className={errors.name ? 'error-field free-text-input' : 'free-text-input'}>
            <span className="label ion-align-items-center">Name <span className="required">*</span></span>
            <IonInput
              value={editState.therapy.name}
              onIonInput={(e) =>
                setEditState((s) => ({ ...s, therapy: { ...s.therapy, name: e.target.value } } as AddTreatmentParams))
              }
              placeholder="-"
              required
              {...register('name')}
            />
            {errors.name && <small className="error">{errors.name.message}</small>}
          </div>

          <div className={errors.description ? 'error-field free-text-input' : 'free-text-input'}>
            <span className="label ion-align-items-center">Description <span className="required">*</span></span>
            <IonInput
              value={description}
              onIonInput={(e) =>
                setDescription(e.target.value as string)
              }
              placeholder="-"
              required
              {...register('description')}
            />
            {errors.description && <small className="error">{errors.description.message}</small>}
          </div>
          <div className={errors.relevance ? 'error-field free-text-input' : 'free-text-input'}>
            <span className="label ion-align-items-center">Relevance <span className="required">*</span></span>
            <IonSelect
              value={editState.therapy.relevance}
              onIonChange={(e) => {
                setEditState((s) =>
                  ({ ...s, therapy: { ...s.therapy, relevance: e.target.value } } as AddTreatmentParams))
              }}
              placeholder="-"
              interface="popover"
              {...register('relevance')}
            >
              <IonSelectOption value="High">High</IonSelectOption>
              <IonSelectOption value="Medium">Medium</IonSelectOption>
              <IonSelectOption value="Low">Low</IonSelectOption>
            </IonSelect>
            {errors.relevance && <small className="error">{errors.relevance.message}</small>}
          </div>
          <div className={errors.type ? 'error-field free-text-input' : 'free-text-input'}>
            <span className="label ion-align-items-center">Type <span className="required">*</span></span>
            <IonSelect
              value={editState.therapy.type}
              onIonChange={(e) => {
                setEditState((s) =>
                  ({ ...s, therapy: { ...s.therapy, type: e.target.value } } as AddTreatmentParams))
                if (e.target.value === 'Clinical Trial') {
                  setEditState((s) =>
                    ({ ...s, therapy: { ...s.therapy, reason: 'Clinical Trial' } } as AddTreatmentParams))
                }
              }}
              placeholder="-"
              interface="popover"
              {...register('type')}
            >
              <IonSelectOption value="therapy">Therapy</IonSelectOption>
              <IonSelectOption value="Clinical Trial">Clinical Trial</IonSelectOption>
            </IonSelect>
            {errors.type && <small className="error">{errors.type.message}</small>}
          </div>

          <div className={errors.reason ? 'error-field free-text-input' : 'free-text-input'}>
            <span className="label ion-align-items-center">Reason <span className="required">*</span></span>
            <IonSelect
              value={editState.therapy.reason}
              onIonChange={(e) =>
                setEditState((s) => ({ ...s, therapy: { ...s.therapy, reason: e.target.value } } as AddTreatmentParams))
              }
              placeholder="-"
              interface="popover"
              {...register('reason')}
            >
              {
                editState.therapy.type === 'therapy' && (
                  <>
                    <IonSelectOption value="FDA-approved therapy, current diagnosis">
                      FDA-approved
                    </IonSelectOption>
                    <IonSelectOption value="FDA-approved therapy, other indicators">
                      Off label
                    </IonSelectOption>
                  </>
                )
              }
              {
                editState.therapy.type === 'Clinical Trial' && (
                  <>
                    <IonSelectOption value="Clinical Trial">Clinical Trial</IonSelectOption>
                  </>
                )
              }
            </IonSelect>
            {errors.reason && <small className="error">{errors.reason.message}</small>}
          </div>

          <div className={errors.genes ? 'error-field free-text-input' : 'free-text-input'}>
            <span className="label ion-align-items-center">Genes <span className="required">*</span></span>
            <IonSelect
              value={editState.therapy.genes}
              onIonChange={(e) =>
                setEditState((s) => ({ ...s, therapy: { ...s.therapy, genes: e.target.value } } as AddTreatmentParams))
              }
              placeholder="-"
              interface="popover"
              multiple
              {...register('genes')}
            >
              {props.genesList.map((gene, index) => (
                <IonSelectOption key={`${gene}-${index}`} value={gene}>{gene}</IonSelectOption>
              ))}
            </IonSelect>
            {errors.genes && <small className="error">{errors.genes.message}</small>}
          </div>
        </form>
      </IonContent>

      <IonFooter>
        <IonToolbar>
          <IonButton slot="end" fill="outline" onClick={() => props.setIsOpen(false)}>
            Cancel
          </IonButton>

          <IonButton
            slot="end"
            fill="solid"
            disabled={isSaving}
            onClick={handleSubmit(save)}
            form="create-treatment-form"
          >
            {isSaving ? <IonSpinner /> : 'Save'}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  )
}

export default ModalCreateTreatment

