import { IonSpinner } from '@ionic/react'
import React from 'react'
import './default-card.scss'

interface DefaultCardProps {
  title: string
  isLoading: boolean
}

const DefaultCard: React.FC<DefaultCardProps> = (props) => {
  return (
    <section className="default-card">
      <div className="dc-title">
        <b>{props.title}</b>
        {props.isLoading ? <IonSpinner name="dots" /> : undefined}
      </div>
      <div className="inside-card">{props.children}</div>
    </section>
  )
}

export default DefaultCard
