import { IonButton } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import './pagination.scss'

export interface PaginationProps {
  quantity: number
  currentPage: number
  onNewPage: (num: number) => void
}

interface PaginationItem {
  label: string | number
  disabled?: boolean
}

const Pagination: React.FC<PaginationProps> = (props) => {
  const itens = Array(props.quantity)
    .fill({})
    .map((_, index) => ({ label: index + 1 }))

  const last: PaginationItem[] = Array(3)
    .fill({})
    .map((_, index) => ({ label: props.quantity - index }))
    .reverse()

  const [pages, setPages] = useState<PaginationItem[]>([])
  const [first3, setFirst3] = useState<PaginationItem[]>([])
  const [last3] = useState<PaginationItem[]>(last)

  const switchCurrent = (pageNumber: number) => {
    if (pageNumber > 0 && pageNumber <= props.quantity) {
      props.onNewPage(pageNumber)
    }
  }

  const makeItens = () => {
    if (props.quantity > 10) {
      const hasFirstItens = first3 && first3.length
      const hasLastItens = last3 && last3.length

      const lastItemOfFirst3 = hasFirstItens && Number(first3[first3.length - 1]?.label)
      const firstItemOfFirst3 = hasFirstItens && Number(first3[0]?.label)
      const fistItemofLast3 = hasLastItens && Number(last[0]?.label)

      const shouldGetNextItens = props.currentPage > lastItemOfFirst3
      const shouldGetPreviousItens = props.currentPage < firstItemOfFirst3
      const currentPageIsNotLastItens = props.currentPage < fistItemofLast3

      if ((shouldGetNextItens || shouldGetPreviousItens) && currentPageIsNotLastItens) {
        const newFristWillOverlapLast = props.currentPage + 2 >= fistItemofLast3
        if (newFristWillOverlapLast) {
          const first: PaginationItem[] = Array(3)
            .fill({})
            .map((_, index) => ({ label: props.currentPage - index }))
            .reverse()
          setFirst3(first)
        } else {
          const first: PaginationItem[] = Array(3)
            .fill({})
            .map((_, index) => ({ label: props.currentPage + index }))
          setFirst3(first)
        }
      }
    } else {
      setPages(itens)
    }
  }

  useEffect(() => {
    makeItens()
  }, [props.currentPage])

  return (
    <section className="pagination">
      <IonButton onClick={() => switchCurrent(1)} disabled={props.currentPage - 1 === 0}>
        {'<<'}
      </IonButton>

      <IonButton onClick={() => switchCurrent(props.currentPage - 1)} disabled={props.currentPage - 1 === 0}>
        {'<'}
      </IonButton>

      {props.quantity <= 10 &&
        pages.map((page, index) => (
          <IonButton
            key={page.label}
            onClick={() => switchCurrent(index + 1)}
            color={index + 1 === props.currentPage ? 'primary' : ''}
          >
            {page.label}
          </IonButton>
        ))}

      {props.quantity > 10 &&
        first3.map((page) => (
          <IonButton
            key={page.label}
            onClick={() => switchCurrent(page.label as number)}
            color={props.currentPage === page.label ? 'primary' : ''}
          >
            {page.label}
          </IonButton>
        ))}
      {props.quantity > 10 && <>...</>}

      {props.quantity > 10 &&
        last3.map((page) => (
          <IonButton
            key={page.label}
            onClick={() => switchCurrent(page.label as number)}
            color={props.currentPage === page.label ? 'primary' : ''}
          >
            {page.label}
          </IonButton>
        ))}

      <IonButton onClick={() => switchCurrent(props.currentPage + 1)} disabled={props.currentPage === props.quantity}>
        {'>'}
      </IonButton>

      <IonButton onClick={() => switchCurrent(props.quantity)} disabled={props.currentPage === props.quantity}>
        {'>>'}
      </IonButton>
    </section>
  )
}

export default Pagination
