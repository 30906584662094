import { IonCard, IonSpinner } from '@ionic/react'
import { route } from 'main/routes'
import { LoginFormValidation } from 'main/validations/usecases/login-form-validation'
import { Validation } from 'main/validations/usecases/validation'
import { ButtonLink, ButtonRound, CardBackground, Message, Title } from 'presentation/components'
import InputAutofill from 'presentation/components/input/input-autofill'
import { useSkipFirstRender } from 'presentation/hooks/skip-first-render'
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { LoginFormState, loginFormState } from './login-form-atom'
import './login-form.scss'

enum FormFields {
  email = 'email',
  password = 'password',
}

export type LoginFormProps = React.ComponentPropsWithRef<typeof IonCard> & {
  onSubmit?: () => void
  className: string
  validations: LoginFormValidation
}

const LoginForm: React.FC<LoginFormProps> = (props: LoginFormProps) => {
  const [state, setState] = useRecoilState(loginFormState)

  const [emailFocus, setEmailFocus] = useState(false)

  const isFirstRender = useSkipFirstRender()

  const validate = (fieldName: string) => {
    if (!isFirstRender) {
      const updateState = (stateFieldName: keyof LoginFormState, value: string | null) => {
        setState((current) => {
          return {
            ...current,
            [stateFieldName]: value,
          }
        })
      }

      const validateField = (validations: Validation[], value: string) =>
        props.validations.getValidationError(validations, value)

      switch (fieldName) {
        case FormFields.email:
          updateState('emailError', validateField(props.validations.email, state.email))
          break
        default:
          break
      }
    }
  }

  useEffect(() => validate(FormFields.email), [state.email])

  const loginButtonDisabled = () => {
    return Boolean(!state.email || !state.email.length || !state.password || !state.password.length)
  }

  return (
    <CardBackground className="login-form">
      <form autoComplete="on">
        <Title size="h1" className="title-center">
          Login
        </Title>
        <div className="login-form-inputs">
          <div style={{ marginBottom: 18 }}>
            <InputAutofill
              label="Email"
              hasText={!!state.email?.length}
              clearButton={true}
              eyeButton={false}
              inputType={'text'}
              onClearText={() => setState((s) => ({ ...s, email: '' }))}
              onIonChange={(ev) => setState((s) => ({ ...s, email: ev.target.value as string }))}
              onIonFocus={() => setEmailFocus(true)}
              onIonBlur={() => setEmailFocus(false)}
              autocomplete="email"
              name="email"
            />
            {state.emailError && !emailFocus && <Message color={'error'}>{state.emailError}</Message>}
          </div>

          <div>
            <InputAutofill
              label="Password"
              hasText={!!state.password?.length}
              clearButton={false}
              eyeButton={true}
              inputType={'password'}
              onClearText={() => setState((s) => ({ ...s, password: '' }))}
              onIonChange={(ev) => setState((s) => ({ ...s, password: ev.target.value as string }))}
            />
          </div>
        </div>

        {state.remoteError && <Message color={'error'}>{state.remoteError}</Message>}

        <ButtonRound
          data-testid="login-form-submit"
          onClick={props.onSubmit}
          disabled={loginButtonDisabled() || state.isLoading}
        >
          {!state.isLoading && 'Login'}
          {state.isLoading && <IonSpinner />}
        </ButtonRound>
      </form>

      <div className="login-links">
        <ButtonLink routerLink={route.forgotPassword.url}>Forgot password?</ButtonLink>
        <br />
      </div>
    </CardBackground>
  )
}

export default LoginForm
