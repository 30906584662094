import { atom } from 'recoil'

export interface ModalEditReportHeaderState {
  specimen?: string | null
  specimenType?: string | null
  reportDate?: string | null
  biopsyDate?: string | null
  ct_dna_tumor_fraction?: string | null
  microsatellite_instability_status?: string | null
  tumor_mutational_burden?: string | null
  hrd_signature?: string | null
  tmb_percentile?: string | null
}

export const modalEditReportHeaderAtom = atom<ModalEditReportHeaderState>({
  key: 'modalEditReportHeaderAtom',
  default: {
    specimen: null,
    specimenType: null,
    reportDate: null,
    biopsyDate: null,
    ct_dna_tumor_fraction: null,
    microsatellite_instability_status: null,
    tumor_mutational_burden: null,
    hrd_signature: null,
    tmb_percentile: null,
  },
})
